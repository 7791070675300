import { Gender } from "../../common/enums/gender.enum";
import { createI18nText } from "../i18n";

export const GenderTexts = createI18nText<{ [key in Lowercase<Gender> | "without"]: string }>({
    he: {
        male: "זכר",
        female: "נקבה",
        without: "ללא",
        other: "אחר",
        none: "ללא"
    },
    ar: {
        male: "ذكر",
        female: "انثى",
        without: "بدون",
        other: "اخر",
        none: "بدون"
    }
})