import { createI18nText } from "../i18n";

export const AlertsTexts = createI18nText({
    he: {
        no_teachers: "טרם קיימים מורים בבית הספר שלך, אנא שבץ מחנכים ומחנכות לאחר הוספתם למערכת",
        didnt_exist_now_they_do: "חלק מהכיתות לא היו קיימות קודם ונוצרו כעת.",
        please_enter_teacher_names: "אנא הכניסו את שמם של מחנכי הכיתות הללו",
        something_wrong_in_file: "נמצא משהו לא תקין בקובץ",
        not_valid_teacher: "משהו לא תקין עבור אחד המורים",
        value_to_change: "הערך לשינוי ב",
        internal_error: "אירעה שגיאה, אנא נסו שנית מאוחר יותר",
        irreversible_action: "פעולה זו אינה הפיכה, האם את/ה בטוח/ה שברצונך למחוק את",
        teacher_deleted_successfully: "המורה נמחק בהצלחה",
        no_internet_on_action: "אינך מחובר לאינטרנט, לא ניתן לבצע פעולה זו כעת",
        link_expire: "סליחה, פג תוקפו של הקישור",
        invalid_link: "סליחה, הקישור אינו תקין",
        choose_g_for_sen: "נא לבחור מגדר למשפט הפתיחה",
        choose_c_for_sen: "נא לבחור קטגוריה למשפט הפתיחה",
        write_g: "נא לציין מגדר",
        no_sen_in_c: "לא נמצאו משפטי פתיחה בקטגוריה",
        try_again_internet: "אין חיבור לאינטרנט, נסה שנית מאוחר יותר.",
        not_registered: "הכתובת אינה רשומה במערכת",
        mail_sent: "נשלחה הודעת אימות לכתובת המייל",
        mail_expired: "פג תוקף מייל האימות. חזור לדף הבית ושלח מייל חדש.",
        change_psw_scd: "הסיסמה שונתה בהצלחה!",
        personal_component: "זוהי הינה קומפוננטה מותאמת אישית",
        delete_error: "אירעה תקלה במחיקה",
        enter_pvt_lst: "אנא הכנס שם פרטי ושם משפחה",
        enter_phone: "אנא הכנס מספר טלפון",
        pls_choose: "אנא בחר",
        enter_address: "אנא הכנס כתובת ומספר בית",
        succed_reset_gp: "הנקודות בבית ספרך אופסו בהצלחה",
        cant_load_info: "לא ניתן לטעון את המידע כרגע",
        another_explenation: "ביכולתך לבדוק בלחיצה על סימן ה i את המבנה המבוקש",
        error_not_now_but_classes: "אירעה שגיאה, לא ניתן לעשות זאת כעת, אך תמיד ניתן לעשות זאת בעריכת הכיתות",
        internet_not_now_but_classes: "אין אינטרנט, לא ניתן לעשות זאת כעת, אך תמיד ניתן לעשות זאת בעריכת הכיתות",
        no_internet_right_now: "אין אינטרנט, לא ניתן לבצע את הפעולה כרגע",
        class_exist: "הכיתה שברצונך לשנות כבר קיימת",
        register_no_internet: "אין אינטרנט, לא ניתן לבצע את ההרשמה כעת",
        class_registered: "כיתה זו כבר רשומה בבית ספרך",
        class_reg_suc: "הכיתה נוספה בהצלחה",
        classes_reg_suc: "הכיתות נוספו בהצלחה!",
        part_valid: "חלק מהמידע בקובץ תקין",
        column_title_invalid: "כותרות העמודות שבקובץ שגויות",
        received_columns: "הכותרות שהתקבלו הן",
        upload_classes_file_error: "אירעה שגיאה בהעלאת קובץ הכיתות, נא עברו עליו או נסו שנית מאוחר יותר",
        error_one_class_file: "אירעה שגיאה עבור אחת הכיתות בעת העלאת קובץ הכיתות\n",
        problem_with_home_teacher: "ישנה בעיה עם שם המחנך",
        attention_teacher: "שימו לב כי מחנך הכיתה המצוין בקובץ צריך להיות רשום במערכת.\nכמו כן, ניתן להעלות כיתה ללא שם פרטי ושם משפחה של מחנך",
        teachers_scc: "המורים נרשמו בהצלחה\nלידיעתך, המורים קיבלו אימייל עם הסיסמה הזמנית שלהם.\nבנוסף, קובץ סיסמאות של המורים מורד למחשב שלך ברגעים אלו",
        link_copied: "הקישור הועתק",
        got_cool_data: "קיבלנו נתונים מגניבים שביקשנו מטופס מסויים ב",
        notice_spaces: "שימו לב להשתמש בכותרות הנכונות, וודאו שאין רווחים מיותרים",
        pms_added: "המשפטים נוספו בהצלחה",
        your_school_is_currently_disabled: "בית ספרך אינו מחובר למערכת נקודה טובה"
    },
    ar: {
        no_teachers: "טרם קיימים מורים בבית הספר שלך, אנא שבץ מחנכים ומחנכות לאחר הוספתם למערכת",
        didnt_exist_now_they_do: "חלק מהכיתות לא היו קיימות קודם ונוצרו כעת.",
        please_enter_teacher_names: "אנא הכניסו את שמם של מחנכי הכיתות הללו",
        something_wrong_in_file: "נמצא משהו לא תקין בקובץ",
        not_valid_teacher: "משהו לא תקין עבור אחד המורים",
        value_to_change: "הערך לשינוי ב",
        internal_error: "אירעה שגיאה, אנא נסו שנית מאוחר יותר",
        irreversible_action: "פעולה זו אינה הפיכה, האם את/ה בטוח/ה שברצונך למחוק את",
        teacher_deleted_successfully: "המורה נמחק בהצלחה",
        no_internet_on_action: "אינך מחובר לאינטרנט, לא ניתן לבצע פעולה זו כעת",
        link_expire: "סליחה, פג תוקפו של הקישור",
        invalid_link: "סליחה, הקישור אינו תקין",
        choose_g_for_sen: "נא לבחור מגדר למשפט הפתיחה",
        choose_c_for_sen: "נא לבחור קטגוריה למשפט הפתיחה",
        write_g: "נא לציין מגדר",
        no_sen_in_c: "לא נמצאו משפטי פתיחה בקטגוריה",
        try_again_internet: "אין חיבור לאינטרנט, נסה שנית מאוחר יותר.",
        not_registered: "הכתובת אינה רשומה במערכת",
        mail_sent: "נשלחה הודעת אימות לכתובת המייל",
        mail_expired: "פג תוקף מייל האימות. חזור לדף הבית ושלח מייל חדש.",
        change_psw_scd: "הסיסמה שונתה בהצלחה!",
        personal_component: "זוהי הינה קומפוננטה מותאמת אישית",
        delete_error: "אירעה תקלה במחיקה",
        enter_pvt_lst: "אנא הכנס שם פרטי ושם משפחה",
        enter_phone: "אנא הכנס מספר טלפון",
        pls_choose: "אנא בחר",
        enter_address: "אנא הכנס כתובת ומספר בית",
        succed_reset_gp: "הנקודות בבית ספרך אופסו בהצלחה",
        cant_load_info: "לא ניתן לטעון את המידע כרגע",
        another_explenation: "ביכולתך לבדוק בלחיצה על סימן ה i את המבנה המבוקש",
        error_not_now_but_classes: "אירעה שגיאה, לא ניתן לעשות זאת כעת, אך תמיד ניתן לעשות זאת בעריכת הכיתות",
        internet_not_now_but_classes: "אין אינטרנט, לא ניתן לעשות זאת כעת, אך תמיד ניתן לעשות זאת בעריכת הכיתות",
        no_internet_right_now: "אין אינטרנט, לא ניתן לבצע את הפעולה כרגע",
        class_exist: "הכיתה שברצונך לשנות כבר קיימת",
        register_no_internet: "אין אינטרנט, לא ניתן לבצע את ההרשמה כעת",
        class_registered: "כיתה זו כבר רשומה בבית ספרך",
        class_reg_suc: "הכיתה נוספה בהצלחה",
        classes_reg_suc: "הכיתות נוספו בהצלחה!",
        part_valid: "חלק מהמידע בקובץ תקין",
        column_title_invalid: "כותרות העמודות שבקובץ שגויות",
        received_columns: "הכותרות שהתקבלו הן",
        upload_classes_file_error: "אירעה שגיאה בהעלאת קובץ הכיתות, נא עברו עליו או נסו שנית מאוחר יותר",
        error_one_class_file: "אירעה שגיאה עבור אחת הכיתות בעת העלאת קובץ הכיתות\n",
        problem_with_home_teacher: "ישנה בעיה עם שם המחנך",
        attention_teacher: "שימו לב כי מחנך הכיתה המצוין בקובץ צריך להיות רשום במערכת.\nכמו כן, ניתן להעלות כיתה ללא שם פרטי ושם משפחה של מחנך",
        teachers_scc: "המורים נרשמו בהצלחה\nלידיעתך, המורים קיבלו אימייל עם הסיסמה הזמנית שלהם.\nבנוסף, קובץ סיסמאות של המורים מורד למחשב שלך ברגעים אלו",
        link_copied: "הקישור הועתק",
        got_cool_data: "קיבלנו נתונים מגניבים שביקשנו מטופס מסויים ב",
        notice_spaces: "שימו לב להשתמש בכותרות הנכונות, וודאו שאין רווחים מיותרים",
        pms_added: "המשפטים נוספו בהצלחה",
        your_school_is_currently_disabled: "בית ספרך אינו מחובר למערכת נקודה טובה"
    }
})