import { createI18nText } from "../i18n";

export const presetMessages = createI18nText({
    he: {
        add: "הוספת משפט",
        category: "קטגוריה",
        the_msg: "נוסח המשפט",
        categories: {
            social: "חברתי",
            emotional: "רגשי",
            educational: "לימודי",
            other: "אחר"
        },
        delete: "האם ברצונך למחוק משפט פתיחה זה?",
        yes_del: "כן, מחק",
        invalid_opening_sentence: "המשפט אינו תקין. (התווים המיוחדים המותרים הם ,-:\"._)",
        the_student_F: "התלמידה",
        the_student_M: "התלמיד",
        in_order_include: "על מנת להוסיף נקודה טובה זו, יש לכלול את המילה",
        please_put: "נא לכלול את המילה",
        only_once: "פעם אחת בלבד",
        cant_F: "לא ניתן לכלול את המילה 'התלמידה' עבור משפט פתיחה זכר",
        if_you_want: "אם ברצונכם לשייך את משפט הפתיחה ל",
        please_do: "נא לציין זאת",
        one: "משפט פתיחה",
        many: "משפטי פתיחה"
    },
    ar: {
        add: "إضافة جملة",
        category: "فئة",
        the_msg: "صياغة الجملة",
        categories: {
            social: "اجتماعي",
            emotional: "عاطفي",
            educational: "تعليمي",
            other: "اخر"
        },
        delete: "هل انت متأكد من حذف هذه الجملة الافتتاحية؟",
        yes_del: "نعم, احذف",
        invalid_opening_sentence: "המשפט אינו תקין. (התווים המיוחדים המותרים הם ,-:\"._)",
        the_student_F: "الطالبة",
        the_student_M: "الطالب",
        in_order_include: "لاضافة كلمة طيبة, يجب شمل الكلمة",
        please_put: "الرجاء شمل الكلمة",
        only_once: "مرة واحدة فقط",
        cant_F: "لا يمكن شمل كلمة “الطالبة” في جملة افتتاحية مذكر",
        if_you_want: "اذا ترغبون  في ربط  الجملة الافتتاحية ل",
        please_do: "يجب ذكر ذلك",
        one: "MISSING",
        many: "MISSING"
    }
})