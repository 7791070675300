import { createI18nText } from "../i18n";
export const StoreTexts = createI18nText({
    he: {
        "asked_titles": "הכותרות המבוקשות הן:",
        "she_not_valid": "והיא אינה תקינה.",
        "found_title": "מצאנו את הכותרת",
        "try_again_columns": "נא נסו שנית מאוחר יותר, או נסו שנית עם העמודות:",
        "err_title": "אירעה שגיאה עם הכותרת",
        "required": "הכותרות המבוקשות",
        "found_title2": "מצאנו בקובץ את הכותרת",
        "found_titles2": "מצאנו בקובץ את הכותרות",
        "are": "הן: ",
        "in_setting": "נמצאות בהסבר שבמסך ההגדרות",
        "the_info": "המידע",
        "in_column": "בעמודה",
        "on_of_the_columns": "באחת העמודות",
        "for_student": "עבור התלמיד",
        "area": "איזור רשומה מספר",
        "teacher_belongs": "המורה שויך לכיתה",
        "teachers_got_belong": "המורים שויכו לכיתות",
        "succesfuly": "בהצלחה",
        "for_class": "עבור הכיתה",
        "for_home_teacher": "עבור המחנך/ת"
    },
    ar: {
        "asked_titles": "הכותרות המבוקשות הן:",
        "she_not_valid": "והיא אינה תקינה.",
        "found_title": "מצאנו את הכותרת",
        "found_titles2": "מצאנו בקובץ את הכותרות",
        "try_again_columns": "נא נסו שנית מאוחר יותר, או נסו שנית עם העמודות:",
        "err_title": "אירעה שגיאה עם הכותרת",
        "required": "הכותרות המבוקשות",
        "found_title2": "מצאנו בקובץ את הכותרת",
        "are": "הן: ",
        "in_setting": "נמצאות בהסבר שבמסך ההגדרות",
        "the_info": "המידע",
        "in_column": "בעמודה",
        "on_of_the_columns": "באחת העמודות",
        "for_student": "עבור התלמיד",
        "area": "איזור רשומה מספר",
        "teacher_belongs": "המורה שויך לכיתה",
        "teachers_got_belong": "המורים שויכו לכיתות",
        "succesfuly": "בהצלחה",
        "for_class": "עבור הכיתה",
        "for_home_teacher": "עבור המחנך/ת"
    }
})