import { createI18nText } from "../i18n";

export const GreetingsTexts = createI18nText({
    he: {
        so_far: "עד כה שלחת",
        x_gps: "נקודות טובות",
        one_sent: "עד כה שלחת נקודה טובה",
        one: "אחת",
        one_s: "נקודה טובה אחת",
        didnt_send: "טרם שלחת נקודות טובות",
        no_points_yet: "טרם נשלחו נקודות טובות",
        my_activity: "הפעילות שלי"
    },
    ar: {
        so_far: "حتى الان ارسلت",
        x_gps: "نقاط جيدة",
        one_sent: "حتى الان ارسلت كلمة طيبة ",
        one: "واحدة",
        one_s: "كلمة طيبة واحدة",
        didnt_send: "لم ترسل بعد نقاط جيدة",
        no_points_yet: "טרם נשלחו נקודות טובות",
        my_activity: "نشاطي"
    }
})