import { i18nRef } from "../index";
import { regexes } from "./consts";

const d = new Date();
export const getYearOfSelectedMonth = (selectedMonth, currMonth = d.getMonth(), currYear = d.getFullYear()) => {
    /**
     // @Param : selectedMonth - number of month to get it's year //!(1-11) 
     // @Param : currMonth - new Date().getMonth()  //!(1-11) 
     // @Param : currYear - new Date().getFullYear
    */
    return ((currMonth >= 8 && currMonth <= 11) && (selectedMonth >= 0 && selectedMonth <= 7))
        ? (Number(currYear) + 1)
        : (((selectedMonth >= 8 && selectedMonth <= 11) && (currMonth >= 0 && currMonth <= 7)) ? (Number(currYear) - 1) : currYear)
}


export function getUrlParam(param, location) {
    try {
        return new URLSearchParams(location.search).get(param)
    } catch (e) { return null }
}


export function validateNewUser(userData, checkGender = false) { // if return value is not -true- there is a .split(";") (counting on the fact that the return value in this case is a string) (CustomUser.addTeachers)
    //validate teacher obj
    // userData: { firstName, lastName, gender, email }
    // genderSelectErr - if err on gender: return error saying: pick again. if false: return error of not valid gender
    let { gender, firstName, lastName, email } = userData;

    // validate gender
    if (checkGender) {
        if (!gender) return `gender;${i18nRef.current.validation.gender_choose}`;
        if (typeof gender !== "string") return `gender;${i18nRef.current.validation.gender_err_msg}`;
        gender = gender.toUpperCase();
        if (gender !== "MALE" && gender !== "FEMALE" && gender !== "OTHER") {
            console.log("wrong gender ", gender);
            return `gender;${i18nRef.current.validation.gender_err_msg}`
        }
    }

    //validate name
    firstName = typeof firstName === "string" ? firstName.trim() : null;
    lastName = typeof lastName === "string" ? lastName.trim() : null
    const bothNames = [firstName, lastName]
    let currNameVali;
    for (let i in bothNames) {
        currNameVali = bothNames[i]
        if (!currNameVali || !currNameVali.length)
            return `${Number(i) === 0 ? 'first' : 'last'}Name;${i18nRef.current.validation.insert_name} ${Number(i) === 0 ? i18nRef.current.generic.private : i18nRef.current.generic.family}`;
        else if (currNameVali.length < 2)
            return `${Number(i) === 0 ? 'first' : 'last'}Name;${i18nRef.current.validation.name_min_length}`;
        else if (currNameVali.length > 20)
            return `${Number(i) === 0 ? 'first' : 'last'}Name;${i18nRef.current.validation.name_max_length}`;
        else {
            let res = regexes.FIRST_LAST_NAME.test(currNameVali);
            if (!res) return `${Number(i) === 0 ? 'first' : 'last'}Name;${i18nRef.current.validation.name_must_contain}`;
        }
    }
    //validate email
    email = typeof email === "string" ? email.trim().toLowerCase().replace(/[\u{0080}-\u{FFFF}]/gu, "") : null;
    if (!email || !email.length) return `email;${i18nRef.current.validation.enter_mail}`;
    if (!regexes.EMAIL.test(email)) return `email;${i18nRef.current.validation.wrong_mail}`;

    return true
}


/**
 * rename an object's key
 * @typedef {{oldKey: string, newKey: string}} renameInfo
 * @param {object} obj
 * @param {renameInfo} info object, containing names of the old key and the new key. the old key will be renamed to the new key.
 */
export function renameKey(obj, { oldKey, newKey }) {
    Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey));
}
