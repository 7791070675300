import { i18nRef } from "../index";


const GenderOptionsLowerCased = {
    MALE: "male",
    FEMALE: "female",
    NONE: "none"
}

export const convertStudentGenderToEng = () => ({ // teacher (CustomUser) has the same gender options
    [i18nRef.current.genders.other]: 'other',
    [i18nRef.current.genders.female]: "female",
    [i18nRef.current.genders.male]: "male"
});

export default GenderOptionsLowerCased;

//TODO use these in more places
//~ i tottaly forgot about these