import { createI18nText } from "../i18n";

export const PopupTexts = createI18nText({
    he: {
        error: {
            load_file: "אירעה שגיאה בעת טעינת הקובץ",
            file_too_long: "אורך הגיליון ארוך מדי, אורכו ",
            uploading_teachers: "אירעה שגיאה בעת העלאת המורים, נא נסו שנית מאוחר יותר",
            create_file: "אירעה תקלה בעת יצירת הקובץ"
        },
        delete_class: "האם ברצונך למחוק את הכיתה",
        edit_class_details: "עריכת פרטי כיתה",
        registrate_class: "רישום כיתה למערכת",
        registrate_student: "רישום תלמיד למערכת",
        one_parent: "מספר טלפון אחד בלבד",
        if: "האם",
        sure_female: "את בטוחה",
        sure_male: "אתה בטוח",
        irreversable_action: "פעולה זו בלתי הפיכה",
        notice_side_effects_of_deleting_a_class: "מחיקה של הכיתה תמחק גם את כל התלמידים המשוייכים לכיתה הזו",
        notice_male: "שים לב",
        notice_female: "שימי לב",
        zero_points: "שברצונך לאפס את הנקודות בכל בית ספרך?",
        succes_add_user: "המשתמש נוסף בהצלחה",
        fyi_welcome_email_was_sent_to_teacher: "לידיעתך, נשלח למורה אימייל עם הסיסמה הזמנית שלו/ה",
        continue_to_upload: "המשך להעלאה",
        file_loading: "הקובץ בטעינה ...",
        succes_teacher_update: "פרטי המורה עודכנו בהצלחה",
        succes_add_admin: "המשתמש נוסף בהצלחה כמנהל",
        approve: "אשר",
        child_report: "דוח תלמיד"
    },
    ar: {
        error: {
            load_file: "حدث خلل ",
            file_too_long: "طول الورقة طويل جدا, طولها",
            uploading_teachers: "حدث خلل اثناء رفع المعلمين, الرجاء المحاولة مرة اخرى لاحقا",
            create_file: "حدث خلل اثناء انشاء الملف"
        },
        delete_class: "هل ترغب في حذف الصف؟",
        edit_class_details: "تعديل تفاصيل الصف",
        registrate_class: "تسجيل الصف في البرنامج",
        registrate_student: "تسجيل طلاب في البرنامج",
        one_parent: "رقم هاتف واحد فقط",
        if: "هل",
        sure_female: "انت متأكدة",
        sure_male: "انت متاكد",
        irreversable_action: "لا يمكن التراجع عن هذه العملية",
        notice_side_effects_of_deleting_a_class: "מחיקה של הכיתה תמחק גם את כל התלמידים המשוייכים לכיתה הזו",
        notice_male: "انتبه",
        notice_female: "انتبهي",
        zero_points: "ترغب في اعادة تعيين جميع النقاط في كل مدرستك؟ ",
        succes_add_user: "تم اضافة اسم المستخدم بنجاح", //todotran translate again //todotrans also in: src/translation/ar.json:449 (succes_add_user)
        fyi_welcome_email_was_sent_to_teacher: "לידיעתך, נשלח למורה אימייל עם הסיסמה הזמנית שלו/ה",
        continue_to_upload: "الاستمرار في التحميل ",
        file_loading: "الملف قيد التحميل",
        succes_teacher_update: "تم تعديل تفاصيل المعلم بنجاح",
        succes_add_admin: "تم اضافة اسم المستخدم بنجاح كمدير",
        approve: "موافقة",
        child_report: "تقرير طالب"
    }
})