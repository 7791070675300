import { createI18nText } from "../i18n";

export const AdminTexts = createI18nText({
    he: {
        home_teacher_male_or_female: "מחנך/ת:",
        edit_something: "עריכת",
        create_school_admin: "סיסמה למערכת עבור המורה",
        download_exmaple: "להורדת קובץ לדוגמא לחצו",
        no_rows_found: "לא נמצאו שורות בקובץ",
        gps: {
            students_name: "שם התלמיד",
            sender: "שם השולח",
            date: "תאריך",
            order_by: "מיין לפי",
            show_more: "הצג עוד ...",
            show_less: "הצג פחות",
            are_u_sure_delete_this_gp: (teacherName: string, studentName: string) =>
                `האם ברצונך למחוק את הנקודה הטובה שנשלחה ע"י ${teacherName ? teacherName : "מורה שנמחק מהמערכת"} ל${studentName}?`,
            no_and_back: "לא, חזור",
            deleted_successfully: "הנקודה נמחקה בהצלחה",
        },
        classes: {
            add_excel: "הוספת כיתות ע\"י קובץ אקסל",
            add: "הוספת כיתה",
            new_class: "כיתה חדשה",
            class_age: "שכבה",
            number: "מספר כיתה",
            home_teacher: "מחנך",
            the_classes: "הכיתות",
            before_save: "לא סיימת למלא מחנכים לכל הכיתות, האם ברצונך לסיים בכל זאת?",
            edit_class: "ערוך כיתה",
            delete_class: "מחק כיתה"
        },
        admins: {
            add_new: "רישום מנהל חדש למערכת"
        },
        all_students: "כל התלמידים בכיתות",
        missing_class_students: "תלמידים ללא כיתה",
        phone_p_1: "טלפון הורה 1",
        phone_p_2: "טלפון הורה 2",
        phone_p_3: "טלפון תלמיד",
        teachers: {
            table_name: "מורים",
            edit: "ערוך מורה",
            edit_the: "המורה",
            teacher_email: "אימייל של המורה",
            add_new: "הוספת מורה",
            add_excel: "הוספת מורים ע\"י קובץ אקסל",
            notice_excel_upload_will_send_emails: "* בסיום העלאת המורים בהצלחה ישלח אימייל לכל מורה עם הסבר ראשוני ועם הסיסמה שנוצרה לו (ניתן לשנותה בכל עת). בנוסף, קובץ סיסמאות יופק ויורד למחשב שלך",
            not_female_teacher: "אינה מחנכת",
            not_male_teacher: "אינו מחנך",
            first_name_of_teacher: "הפרטי של המורה",
            last_name_of_teacher: "שם המשפחה של המורה",
            reset_teacher_password: "איפוס סיסמה",
            reset_password_are_you_sure: (teacherName: string) => `האם ברצונך לאפס את הסיסמה של ${teacherName}? סיסמה חדשה תיווצר עבור המורה ותוצג לך בטבלה באופן זמני`,
            reset_teacher_password_success_text: (teacherName: string) => `הסיסמה של המורה ${teacherName} שונתה בהצלחה!\nנשלח אימייל למורה עם הסיסמה החדשה.`,
            reset_teacher_password_success_text_in_addition: "בנוסף ניתן לראות את הסיסמה בטבלת המורים שלפניך, באופן זמני",
            password_copied: "הסיסמה הועתקה",
        },
        students: {
            add_excel: "הוספת תלמידים ע\"י קובץ אקסל",
            add_student: "הוספת תלמיד",
            added_F: "התלמידה נוספה בהצלחה",
            added_M: "התלמיד נוסף בהצלחה",
            added: "התלמידים נוספו",
            not_added_F: "לא נוצרה",
            not_added_M: "לא נוצר",
            not_added: "לא נוצרו",
            exists: "קיימים במערכת",
            exists_M: "קיים במערכת",
            exists_F: "קיימת במערכת",
            because: "מפני ש",
            new_student: "תלמיד חדש",
            edit_female: "ערוך תלמידה",
            edit_male: "ערוך תלמיד",
            delete_female: "מחק תלמידה",
            delete_male: "מחק תלמיד",
            delete_marked: "מחק את כל המסומנים",
            marked_class: "שייך כיתה לכל המסומנים",
            her_name: "שמה",
            his_name: "שמו",
            delete: "מחיקה",
            move_to_class: "העבר לכיתה",
            delete_all_marked: "מחיקת כל התלמידים המסומנים",
            move_all: "העברת כל התלמידים המסומנים כיתה",
            confirm: {
                del_1_student: "פעולה זו אינה הפיכה, האם את/ה בטוח/ה שברצונך למחוק את התלמיד?",
                del_students: "פעולה זו אינה הפיכה, האם את/ה בטוח/ה שברצונך למחוק את התלמידים?",
                are_you_sure_delete: "האם את/ה בטוח/ה שברצונך למחוק את",
                what_class_one: "לאיזו כיתה תרצה/י לשייך את התלמיד/ה שנבחר/ה?",
                what_class_multi: "לאיזו כיתה תרצה/י לשייך את התלמידים שנבחרו?"
            }
        },
        preset_messages: {
            text: "טקסט",
            used_x_times: "שימושים בבית הספר",
            all_presets: "כל משפטי הפתיחה",
            school_presets: "משפטי הפתיחה של בית הספר",
            all_genders: "כל המגדרים",
            all_categories: "כל הקטגוריות",
            add_message: "הוספת משפט פתיחה",
            preset_text: "נוסח המשפט"
        },
        projects: {
            new_project: "פרויקט חדש",
            second_t: "כותרת משנה",
            deleted_opening: "משפטי פתיחה שהוסרו",
            personal_delete_opening: "משפטי פתיחה אישיים שהוסרו"
        },
        upload_excel: "העלאת קובץ אקסל",
        info_about_excel: "מידע לגבי",
        submit: "העלה קובץ סופית",
        error_try_again: "אירעה שגיאה, אנא נסו שנית מאוחר יותר",

        place_holder: {
            teacher_name: "שם המורה",
            parent1: "מספר טלפון הורה 1",
            parent2: "מספר טלפון הורה 2",
            search: "חפש..",
            "studentPhone": "מספר טלפון תלמיד",

        },
        signup: {
            first_name: "שם פרטי",
            first_name_of_teacher: "שם פרטי של המורה",
            last_name_of_teacher: "שם משפחה של המורה",
            last_name: "שם משפחה",
            confirm_p: "הקלד את הסיסמה שנית",
            uniqe: "*  שמות המורים בבית הספר צריכים להיות ייחודיים על מנת שהמערכת תוכל להבדיל בניהם"
        },
        settings: {
            inc_grades: "העלאת התלמידים כיתה",
            inc_grades_desc: "השנה הסתיימה? בלחיצה, כל כיתה בבית הספר תתקדם להיות הכיתה הבאה. לדוגמא: א תהיה ב, ב תהיה ג ותלמידי יב יהיו ללא כיתה",
            inc_grades_button_text: "העלאת כיתות",
            are_you_sure_inc_grades: "שים/י לב, האם ברצונך להעלות כל תלמיד כיתה אחת למעלה?",
            not_yet: "עוד לא, בטל",
            inc_grades_successfully: "הכיתות עודכנו בהצלחה",
        }
    },
    ar: {

        home_teacher_male_or_female: "مربي/ة",
        edit_something: "تعديل",
        create_school_admin: "كلمة مرور للبرنامج للمعلمة",
        download_exmaple: "انقر هنا لمشاهدة كيفية تحميل ملف ",
        no_rows_found: "לא נמצאו שורות בקובץ",
        gps: {
            students_name: "اسم الطالب",
            sender: "اسم المرسل",
            date: "تاريخ",
            order_by: "صنف حسب",
            show_more: "اعرض المزيد",
            show_less: "اعرض أقل"
        },
        classes: {
            add_excel: " \"اضافة صفوف عن طريق برنامج اكسل",
            add: "اضافة صف",
            new_class: "صف جديد",
            class_age: "طبقة",
            number: "رقم الصف",
            home_teacher: "مربي",
            the_classes: "صفوف",
            before_save: "لم تنهي بعد تعبئة مربيين لجميع الصفوف, هل ترغب بالانهاء رغم من ذلك؟",
            edit_class: "تعديل  صف",
            delete_class: "حذف الصف"
        },
        admins: {
            add_new: "تسجيل مدير جديد للبرنامج"
        },
        all_students: "جميع الطلاب",
        missing_class_students: "طلاب بدون صف",
        phone_p_1: "رقم هاتف الأهل 1",
        phone_p_2: "رقم هاتف الأهل 2",
        phone_p_3: "هاتف الطالب",
        teachers: {
            table_name: "معلمين",
            edit: "تعديل معلم",
            edit_the: "المعلم",
            teacher_email: "بريد الالكتروني للمعلم",
            add_new: "اضافة معلم",
            add_excel: " \"اضافة معلمين عن طريق برنامج اكسل",
            notice_excel_upload_will_send_emails: "* בסיום העלאת המורים בהצלחה ישלח אימייל לכל מורה עם הסבר ראשוני ועם הסיסמה שנוצרה לו (ניתן לשנותה בכל עת). בנוסף, קובץ סיסמאות יופק ויורד למחשב שלך",
            not_female_teacher: "غير مربية",
            not_male_teacher: "غير مربي",
            first_name_of_teacher: "اسم المعلم الشخصي",
            last_name_of_teacher: "سم عائلة المعلم",
            reset_teacher_password: "איפוס סיסמה",
            reset_password_are_you_sure: (teacherName: string) => `האם ברצונך לאפס את הסיסמה של ${teacherName}? סיסמה חדשה תיווצר עבור המורה ותוצג לך בטבלה באופן זמני`,
            reset_teacher_password_success_text: (teacherName: string) => `הסיסמה של המורה ${teacherName} שונתה בהצלחה!\nנשלח אימייל למורה עם הסיסמה החדשה.`,
            reset_teacher_password_success_text_in_addition: "בנוסף ניתן לראות את הסיסמה בטבלת המורים שלפניך, באופן זמני",
            password_copied: "סיסמה הועתקהה",
        },
        students: {
            add_excel: " \"اضافة طلاب عن طريق برنامج اكسل",
            add_student: "اضافة طالب",
            added_F: "تم اضافة الطالبة بنجاح",
            added_M: "تم اضافة الطالب بنجاح",
            added: "تم اضافة الطلاب",
            not_added_F: "لم تتم انشاؤها",
            not_added_M: "لم يتم انشاؤه",
            not_added: "لم يتم انشاؤهم",
            exists: "قائمين في البرنامج",
            exists_M: "قائم في البرنامج",
            exists_F: "قائمة في البرنامج",
            because: "بسبب",
            new_student: "طالب جديد",
            edit_female: "تعديل طالبة",
            edit_male: "تعديل طالب",
            delete_female: "حذف طالبة",
            delete_male: "حذف طالب",
            delete_marked: "حذف كل الذين تم تعيينهم",
            marked_class: "",
            her_name: "اسمها",
            his_name: "اسمه",
            delete: "حذف ",
            move_to_class: "انتقل لصف",
            delete_all_marked: "حذف جميع الطلاب الذين تم تعيينهم",
            move_all: "نقل جميع الطلاب الذين تم تعيينهم ",
            confirm: {
                del_1_student: "هذه العملية لا يمكن التراجع عنها, هل انت متأكد من حذف الطالب؟",
                del_students: "هذه العملية لا يمكن التراجع عنها, هل انت متأكد من حذف الطلاب؟",
                are_you_sure_delete: "هل انت متأكد من حذف ال؟",
                what_class_one: "لأي صف تريد ضم الطالب الذي تم اختياره؟",
                what_class_multi: "لأي صف تريد ضم الطلاب الذين تم اختيارهم"
            }
        },
        preset_messages: {
            text: "טקסט",
            used_x_times: "שימושים בבית הספר",
            all_presets: "כל משפטי הפתיחה",
            school_presets: "משפטי הפתיחה של בית הספר",
            all_genders: "כל המגדרים",
            all_categories: "כל הקטגוריות",
            add_message: "הוספת משפט פתיחה",
            preset_text: "נוסח המשפט"
        },
        projects: {
            new_project: "مشروع جديد",
            second_t: "عنوان فرعي",
            deleted_opening: "الجمل الافتتاحية التي تمت ازالتها",
            personal_delete_opening: "الجمل الافتتاحية الشخصية التي تم ازالتها"
        },
        upload_excel: "ارفاق ملف اكسل",
        info_about_excel: "معلومات حول",
        submit: "ارفاق ملف نهائي",
        error_try_again: "هنالك خلل ما, الرجاء المحاولة في وقت لاحق",

        place_holder: {
            teacher_name: "اسم العلم",
            parent1: "رقم هاتف الوالد 1",
            parent2: "رقم هاتف الوالد 2",
            studentPhone: "رقم هاتف الطالب",
            search: "البحث"
        },
        signup: {
            first_name: "الاسم الشخصي",
            first_name_of_teacher: "اسم المعلم الشخصي",
            last_name_of_teacher: "اسم العائلة الشخصي",
            last_name: "اسم العائلة",
            confirm_p: "ادخل كلمة المرور مرة اخرى",
            uniqe: "يجب ان تكون اسماء المعلمين مميزة ليتمكن النظام من التمييز بينهم"
        },
        settings: {
            inc_grades: "העלאת התלמידים כיתה",
            inc_grades_desc: "השנה הסתיימה? בלחיצה, כל כיתה בבית הספר תתקדם להיות הכיתה הבאה. לדוגמא: א תהיה ב, ב תהיה ג ותלמידי יב יהיו ללא כיתה",
            inc_grades_button_text: "העלאת כיתות",
            are_you_sure_inc_grades: "שים/י לב, האם ברצונך להעלות כל תלמיד כיתה אחת למעלה?",
            not_yet: "עוד לא, בטל",
            inc_grades_successfully: "הכיתות עודכנו בהצלחה",
        }
    }
})