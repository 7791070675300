import { createI18nText } from "../i18n";

export const ValidationText = createI18nText({
    he: {
        name_min_length: "יש להכיל 2 תווים לפחות",
        name_max_length: "אין להכיל יותר מ20 תווים",
        name_must_contain: "חייב להכיל אותיות או מספרים בלבד",
        check_format: "ביכולתך לראות את המבנה המבוקש בלחיצה על סימן ה i",
        invalid_phone: "מספר הטלפון לא תקין",
        enter_mail: "אנא הכנס כתובת מייל",
        wrong_mail: "כתובת המייל שגויה",
        wrong_mail2: "כתובת המייל אינה תקינה",
        enter_psw: "אנא הכנס סיסמה",
        psw_min_length: "הסיסמה חייבת להכיל לפחות 8 תווים",
        psw_must_contain: "הסיסמה חייבת להכיל מספר, אות גדולה ואות קטנה באנגלית",
        psw_again: "אנא הכנס סיסמה שנית",
        psw_no_match: "הסיסמה אינה תואמת",
        internal_error: "קרתה בעיה נא עברו על השדות או נסו שנית מאוחר יותר",
        only_letters: "יש להכיל רק אותיות",
        invalid: "לא תקין",
        invalid_male: "אינו תקין",
        first_name_must: "יש להכניס שם פרטי",
        last_name_must: "יש להכניס שם משפחה",
        teacher_must: "הפרטי של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        teacher_last_must: "אינו תקין, שם המשפחה של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        must_for_name: "יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        part_invalid: "חלק מהמידע אינו תקין",
        teacher_last_and_first: "הפרטי והמשפחה של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        code_length: "יש להכיל בדיוק שישה ספרות",
        only_numbers: "יש להכיל ספרות בלבד",
        must_put_mail: "ש לציין כתובת מייל",
        write: "נא לכתוב משהו",
        gp_empty: "נקודה טובה לא יכולה להיות ריקה",
        gp_max_length: "נקודה טובה מוגבלת לאורך של כ5000 אותיות",
        gp_cant: "אין להכיל סימנים מיוחדים, למעט ,-:!\"._ ואימוג'ים",
        one_or_more: "אחד או יותר מן הפרטים שהזנת אינם נכונים",
        insert_psw_name: "אנא הכנס שם משתמש וסיסמה.",
        gender_choose: "אנא בחר מגדר",
        insert_name: "אנא הכנס שם",
        mail_exist: "האימייל שהכנסת כבר קיים במערכת.",
        psw_exist: "סיסמה זו הייתה כבר בשימוש, בחר סיסמה אחרת.",
        not_varified_address: "כתובת לא מאומתת",
        psw_must_general: "הסיסמה חייבת להכיל אות קטנה ואות גדולה באנגלית, מספר, ולהיות באורך של 8 ספרות ",
        invalid_phone_p: "מספר פלאפון לא תקין",
        pls_enter: "אנא הכנס",
        must_letters: "חייב להכיל רק אותיות",
        less_30: "השם חייב להכיל פחות מ30 תווים",
        name_hb: "השם חייב להכיל רק אותיות בעברית",
        phone_exactly: "מספר הטלפון חייב להכיל בדיוק",
        enter_verify_psw: "אנא הכנס אימות סיסמה",
        less_than: "חייב להכיל פחות מ",
        address_must: "חייב להכיל כתובת בעברית ומספר",
        wrong_address: "כתובת המגורים שהזנת שגויה",
        more_than_0: "להיות גדול מ-0",
        till_9: "להכיל עד 9 ספרות",
        complete_number: "להיות מספר שלם",
        gp_too_log: "הנקודה הטובה ארוכה מדי, האורך המקסימלי הוא 500 תווים",
        mail_try_again: "אירעה שגיאה, נא נסו לתקן את המייל או שנית מאוחר יותר",
        invalid_one_stident: "עבור אחד התלמידים אינו תקין",
        names_err_msg: "יכול להכיל אותיות באנגלית ובעברית, מקפים וגרשיים בלבד",
        grade_err_msg: "יכול להיות א, ב, ג, ד, ה, ו, ז או ח",
        class_err_msg: "יכול להיות בין 1 ל15",
        phone1_err_msg: "יכול להכיל 10 ספרות (אפס בהתחלה) או 9 ספרות (בלי אפס בהתחלה) עם אפשרות למקפים במקומות הרלונטים",
        phone2_err_msg: "יכול להכיל 10 ספרות (אפס בהתחלה) או 9 ספרות (בלי אפס בהתחלה) עם אפשרות למקפים במקומות הרלונטים (שדה רשות)",
        gender_err_msg: "יכול להיות זכר, נקבה או אחר",
        preset_category: "בחר קטגוריה",
        short_text: "הטקסט קצר מדי",
        the_info_col: "המידע בעמודת",
        corrupted_found: "אינו תקין. המידע השגוי הוא"
    },
    ar: {
        name_min_length: "יש להכיל 2 תווים לפחות",
        name_max_length: "אין להכיל יותר מ20 תווים",
        name_must_contain: "חייב להכיל אותיות או מספרים בלבד",
        check_format: "ביכולתך לראות את המבנה המבוקש בלחיצה על סימן ה i",
        invalid_phone: "מספר הטלפון לא תקין",
        enter_mail: "אנא הכנס כתובת מייל",
        wrong_mail: "כתובת המייל שגויה",
        wrong_mail2: "כתובת המייל אינה תקינה",
        enter_psw: "אנא הכנס סיסמה",
        psw_min_length: "הסיסמה חייבת להכיל לפחות 8 תווים",
        psw_must_contain: "הסיסמה חייבת להכיל מספר, אות גדולה ואות קטנה באנגלית",
        psw_again: "אנא הכנס סיסמה שנית",
        psw_no_match: "הסיסמה אינה תואמת",
        internal_error: "קרתה בעיה נא עברו על השדות או נסו שנית מאוחר יותר",
        only_letters: "יש להכיל רק אותיות",
        invalid: "לא תקין",
        invalid_male: "אינו תקין",
        first_name_must: "יש להכניס שם פרטי",
        last_name_must: "יש להכניס שם משפחה",
        teacher_must: "הפרטי של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        teacher_last_must: "אינו תקין, שם המשפחה של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        must_for_name: "יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        part_invalid: "חלק מהמידע אינו תקין",
        teacher_last_and_first: "הפרטי והמשפחה של המורה יכול להכיל אותיות, רווחים ואת הסימנים '\"-`”’ בלבד",
        code_length: "יש להכיל בדיוק שישה ספרות",
        only_numbers: "יש להכיל ספרות בלבד",
        must_put_mail: "ש לציין כתובת מייל",
        write: "נא לכתוב משהו",
        gp_empty: "נקודה טובה לא יכולה להיות ריקה",
        gp_max_length: "נקודה טובה מוגבלת לאורך של כ5000 אותיות",
        gp_cant: "אין להכיל סימנים מיוחדים, למעט ,-:!\"._ ואימוג'ים",
        one_or_more: "אחד או יותר מן הפרטים שהזנת אינם נכונים",
        insert_psw_name: "אנא הכנס שם משתמש וסיסמה.",
        gender_choose: "אנא בחר מגדר",
        insert_name: "אנא הכנס שם",
        mail_exist: "האימייל שהכנסת כבר קיים במערכת.",
        psw_exist: "סיסמה זו הייתה כבר בשימוש, בחר סיסמה אחרת.",
        not_varified_address: "כתובת לא מאומתת",
        psw_must_general: "הסיסמה חייבת להכיל אות קטנה ואות גדולה באנגלית, מספר, ולהיות באורך של 8 ספרות ",
        invalid_phone_p: "מספר פלאפון לא תקין",
        pls_enter: "אנא הכנס",
        must_letters: "חייב להכיל רק אותיות",
        less_30: "השם חייב להכיל פחות מ30 תווים",
        name_hb: "השם חייב להכיל רק אותיות בעברית",
        phone_exactly: "מספר הטלפון חייב להכיל בדיוק",
        enter_verify_psw: "אנא הכנס אימות סיסמה",
        less_than: "חייב להכיל פחות מ",
        address_must: "חייב להכיל כתובת בעברית ומספר",
        wrong_address: "כתובת המגורים שהזנת שגויה",
        more_than_0: "להיות גדול מ-0",
        till_9: "להכיל עד 9 ספרות",
        complete_number: "להיות מספר שלם",
        gp_too_log: "הנקודה הטובה ארוכה מדי, האורך המקסימלי הוא 500 תווים",
        mail_try_again: "אירעה שגיאה, נא נסו לתקן את המייל או שנית מאוחר יותר",
        invalid_one_stident: "עבור אחד התלמידים אינו תקין",
        names_err_msg: "יכול להכיל אותיות באנגלית ובעברית, מקפים וגרשיים בלבד",
        grade_err_msg: "יכול להיות א, ב, ג, ד, ה, ו, ז או ח",
        class_err_msg: "יכול להיות בין 1 ל15",
        phone1_err_msg: "יכול להכיל 10 ספרות (אפס בהתחלה) או 9 ספרות (בלי אפס בהתחלה) עם אפשרות למקפים במקומות הרלונטים",
        phone2_err_msg: "יכול להכיל 10 ספרות (אפס בהתחלה) או 9 ספרות (בלי אפס בהתחלה) עם אפשרות למקפים במקומות הרלונטים (שדה רשות)",
        gender_err_msg: "יכול להיות זכר, נקבה או אחר",
        preset_category: "בחר קטגוריה",
        short_text: "הטקסט קצר מדי",
        the_info_col: "המידע בעמודת",
        corrupted_found: "אינו תקין. המידע השגוי הוא"
    }
})