import { createI18nText } from "../i18n";

export const GradesTexts = createI18nText({
    he: {

        1: "א",
        2: "ב",
        3: "ג",
        4: "ד",
        5: "ה",
        6: "ו",
        7: "ז",
        8: "ח",
        9: "ט",
        10: "י",
        11: "יא",
        12: "יב"
    },
    ar: {
        1: "أول",
        2: "ثاني",
        3: "ثالث",
        4: "رابع",
        5: "خامس",
        6: "سادس",
        7: "ז",
        8: "ח",
        9: "وين",
        10: "ي",
        11: "إيو",
        12: "יב"
    }
})