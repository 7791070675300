import { createI18nText } from "../i18n";

export const UnsubscribeTexts = createI18nText({
    he: {
        title: "הסרה מרשימת התפוצה",
        text: (email: string): string => `בלחיצה על הכפתור, לא ישלח יותר אימייל חודשי לכתובת ${email} המתזכר את מחנך/ת הכיתה על תלמידים שטרם קיבלו נקודה טובה החודש`,
        button_text: "להסרה",
        success_msg: (email: string): string => `האימייל ${email} הוסר בהצלחה`,
        success_sub_msg: <>לביטול ההסרה ולקבלת אימייל חודשי למחנך/ת עם תזכורת לתלמידים שטרם קיבלו נקודה טובה בחודש, יש לפנות אלינו לכתובת <a href="mailto:good.points.system@gmail.com">good.points.system@gmail.com</a></>
    },
    ar: {
        title: "הסרה מרשימת התפוצה",
        text: (email: string): string => `בלחיצה על הכפתור, לא ישלח יותר אימייל חודשי לכתובת ${email} המתזכר את מחנך/ת הכיתה על תלמידים שטרם קיבלו נקודה טובה החודש`,
        button_text: "להסרה",
        success_msg: (email: string): string => `האימייל ${email} הוסר בהצלחה`,
        success_sub_msg: <>לקבלת אימייל חודשי למחנך/ת עם תזכורת לתלמידים שטרם קיבלו נקודה טובה בחודש, יש לפנות אלינו לכתובת <a href="mailto:good.points.system@gmail.com">good.points.system@gmail.com</a></>
    }
})