import { createI18nText } from "../i18n";

export const AccessibilityTexts = createI18nText({
    he: {
        header: "הצהרת נגישות",
        general_header: "כללי",
        general_content: "  התאמות הנגישות של החברה בוצעו עפ״י המלצות התקן הישראלי (ת״י 5568)        לנגישות תכנים באינטרנט ברמת AA וכן עפ״י מסמך 2.0 WCAG הבינלאומי.\n במקומות בהם התקן לא התאים בוצעו התאמות נגישות בהתאם ליכולות הנגישות של        מערכת ההפעלה.",
        os_header: "מערכות הפעלה",
        os_content: "אפליקציית החברה נבדקה במערכות הפעלה Android - הבדיקות בוצעו במכשיר        samsung s9 שמריץ גירסת Android 9. בנוסף הבדיקות בוצעו במכשיר iPhone        שמריץ גרסת IOS 12.2.",
        accessibility_header: "נגישות",
        accessibility_content: " נקודה טובה ממשיכה כל העת לשפר את השרות הניתן לאנשים עם מוגבלות. אם        נתקלתם בשירות לא נגיש או בכל שאלה ו/או הצעה בנושא, אנו פנו לרכזת        הנגישות גב' לאה ברקוביץ, באחד מהאמצעים הבאים:",
        mail: "מייל",
        phone: "טלפון"
    },
    ar: {
        header: "הצהרת נגישות",
        general_header: "כללי",
        general_content: "  התאמות הנגישות של החברה בוצעו עפ״י המלצות התקן הישראלי (ת״י 5568)        לנגישות תכנים באינטרנט ברמת AA וכן עפ״י מסמך 2.0 WCAG הבינלאומי.\n במקומות בהם התקן לא התאים בוצעו התאמות נגישות בהתאם ליכולות הנגישות של        מערכת ההפעלה.",
        os_header: "מערכות הפעלה",
        os_content: "אפליקציית החברה נבדקה במערכות הפעלה Android - הבדיקות בוצעו במכשיר        samsung s9 שמריץ גירסת Android 9. בנוסף הבדיקות בוצעו במכשיר iPhone        שמריץ גרסת IOS 12.2.",
        accessibility_header: "נגישות",
        accessibility_content: " נקודה טובה ממשיכה כל העת לשפר את השרות הניתן לאנשים עם מוגבלות. אם        נתקלתם בשירות לא נגיש או בכל שאלה ו/או הצעה בנושא, אנו פנו לרכזת        הנגישות גב' לאה ברקוביץ, באחד מהאמצעים הבאים:",
        mail: "מייל",
        phone: "טלפון"
    }
})