import { createI18nText } from "../i18n";

export const ErrorTexts = createI18nText({
    he: {
        school_code: {
            length: "סמל בית הספר מכיל 6 ספרות",
            digits: "סמל בית הספר צריך להכיל ספרות בלבד"
        },
        not_found: "לא נמצאו",
        reset_p: {
            diff_p: "אנא הכנס סיסמה השונה מהנוכחית",
            Az1: "הסיסמה צריכה להכיל אות קטנה, אות גדולה ומספר",
            valid_p: "הסיסמה צריכה להיות באורך 8 תווים לפחות ולהכיל אות קטנה, אות גדולה ומספר",
            new_p: "אנא הכנס סיסמה חדשה",
            no_match: "הסיסמאות אינן תואמות"
        }
    },
    ar: {
        school_code: {
            length: "رمز المدرسة يحتوي على 6 أرقام",
            digits: "رمز المدرسة يجب ان يحتوي على أرقام فقط"
        },
        not_found: "غير موجود",
        reset_p: {
            diff_p: "الرجاء ادخال كلمة مرور تختلف عن الموجودة",
            Az1: "كلمة المرور يجب ان تحتوى على حرف صغير, حرف كبير ورقم",
            valid_p: "كلمة المرور يجب ان تحتوي على 8 خانات على الأقل وحرف صغير, حرف كبير ورقم",
            new_p: "الرجاء ادخال كلمة مرور جديدة",
            no_match: "كلمات المرور غير متوافقة"
        }
    }
})