import { i18nRef } from "../index";

// import { getT } from "../translation/GPi18n";
const TablesConsts = {
    ADMIN_POINTS_TABLE: "u", // used for Router path
    ADMIN_STUDENTS_TABLE: "v", // used for Router path
    ADMIN_CLASSES_TABLE: "x", // used for Router path
    ADMIN_TEACHERS_TABLE: "i", // used for Router path
    ADMIN_SETTINGS: "w", // used for Router path
    ADMIN_PRESET_MESSAGES: "p", // used for Router path   
}
export { TablesConsts };

const consts = {
    SCHOOL_SELECTED: -999,
    NO_CLASS_FILTER: 0,
    NO_STUDENTS_FILTER: 0,

    EXCEL_STUDENT_POPUP_KEY: 'excelStudentPopupKey',
    EXCEL_POPUP_BACKGROUND_KEY: 'excelPopupBackgroundKey',

    SMS_EXAMPLE_PAGE_KEY: 'smsPageExampleKey',


    CATEGORY_CLASS_MAME: "nav-link text-center align-middle",
    SELECTED_CATEGORY_CLASS_NAME: "nav-link text-center align-middle active",

    GP_LIST_FETCH_LIMIT: 50,
    STUDENTS_LIST_FETCH_LIMIT: 50,
    GP_BY_STUDENT_FETCH_LIMIT: 50,

    TEXT_BOX_CHAR_LIMIT: 35,


    EXCEL_HEADERS: ["firstName", "lastName", "grade", "classIndex", "phoneNumber1", "phoneNumber2", "phoneNumber3", "gender"],

    CLASSES_EXCEL_HEADERS: ["grade", "classIndex", "teacherFirstName", "teacherLastName"],

    EXCEL_TEMPLATE_STUDENTS_NAME: "students_example_file.xlsx",
    EXCEL_TEMPLATE_CLASSES_NAME: "classes_example_file.xlsx",

    ADMIN_NO_FILTER: null,

    ADMIN_POINTS_CLASSES_SORT: "ADMIN_POINTS_CLASSES_SORT",
    ADMIN_POINTS_TEACHERS_SORT: "ADMIN_POINTS_TEACHERS_SORT",
    ADMIN_POINTS_DATE_SORT: "ADMIN_POINTS_DATE_SORT",

    originalAdminStudentsList: null,
    originalAdminClassesList: null,

    ADMIN_STUDENTS_FETCH_LIMIT: 10,// 50,
    ADMIN_FLOATING_STUDENTS_FETCH_LIMIT: 20,
    ADMIN_CLASSES_FETCH_LIMIT: 10,// 50,
    ADMIN_POINTS_FETCH_LIMIT: 10,// 50,

    ADMIN_STUDENTS_FLOATING: "FLOATIN_GFDSA",
    // ADMIN_STUDENTS_WITH_CLASS: "WITH_CLASS_FDSAvc", // maybe later
    ADMIN_STUDENTS_ALL: "ALL_nvaTEZ",

    ...TablesConsts,

    JUST_GO_BACK: 99, // no specific url, just history.goBack()

    SUCCESSFULLY_UPDATED_STUDENT: "SUCCESSFULLY_UPDATED_STUDENT",
    HEBREW_EXCEL_HEADERS: () => [i18nRef.current.admin.signup.first_name, i18nRef.current.admin.signup.last_name, i18nRef.current.admin.classes.class_age, i18nRef.current.admin.classes.number, i18nRef.current.admin.phone_p_1, i18nRef.current.admin.phone_p_2, i18nRef.current.admin.phone_p_3, i18nRef.current.generic.gender],
    CLASSES_HEADERS_OBJ: () => ({
        [i18nRef.current?.admin.classes.class_age.trim()]: "grade",
        [i18nRef.current?.admin.classes.number.trim()]: "classIndex",
        [i18nRef.current?.excel_explanation.T_first_name.trim()]: "teacherFirstName",
        [i18nRef.current?.excel_explanation.T_last_name.trim()]: "teacherLastName",
    }),

}

export default consts;

export const ENG_TO_HEB_STUDENTS_EXCEL_HEADERS = (engHeader) => {
    let index = consts.EXCEL_HEADERS.findIndex(eng => engHeader === eng);
    if (!isNaN(Number(index))) return consts.HEBREW_EXCEL_HEADERS()[index];
    return null
}
export const ENG_TO_HEB_CLASSES_EXCEL_HEADERS = (engHeader) => {

    const headers = {
        "grade": i18nRef.current?.admin.classes.class_age.trim(),
        "classIndex": i18nRef.current?.admin.classes.number.trim(),
        "teacherFirstName": i18nRef.current?.excel_explanation.T_first_name.trim(),
        "teacherLastName": i18nRef.current?.excel_explanation.T_last_name.trim(),
    };
    return headers[engHeader];
}

export const STUDENTS_EXCEL_HEADERS_TRANSLATE = () => {
    return {
        [i18nRef.current.admin.signup.first_name]: "firstName",
        [i18nRef.current.admin.signup.last_name]: "lastName",
        [i18nRef.current.admin.classes.class_age]: "grade",
        [i18nRef.current.admin.classes.number]: "classIndex",
        [i18nRef.current.admin.phone_p_1]: "phoneNumber1",
        [i18nRef.current.admin.phone_p_2]: "phoneNumber2",
        [i18nRef.current.admin.phone_p_3]: "phoneNumber3",
        [i18nRef.current.generic.gender]: "gender"
    }
}

export const regexes = {
    FIRST_LAST_NAME: /^[a-zA-Zא-ת \u0621-\u064A '"`”’-]+$/,
    PHONE_NUMBER: /^(0[1-9][0-9]-?[0-9]{3}-?[0-9]{4})$/,
    GENDER: /female|FEMALE|male|MALE|other|OTHER/,
    PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
    GOOD_POINT: /^[a-zA-Z0-9א-ת\u0621-\u064A ,-:"'!._\r?\n\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]+$/gu // must be a string!!!s
}
export const validationRules = {
    textRule: { type: 'string', format: { pattern: regexes.FIRST_LAST_NAME, flags: "i", message: "invalid chars" } }, //validation for firstName
    gradeRule: { type: 'string', format: { pattern: '[אבגדהוזחטי]', flags: "i", message: "invalid chars" } }, //validation for grade // grades change //TODOOOOO reut shani
    classIndexRule: { type: 'number', numericality: { greaterThan: 0, lessThanOrEqualTo: 15 } }, //validation for classIndex
    phoneNumberRule: { type: 'string', format: { pattern: regexes.PHONE_NUMBER, flags: "gim", message: "invalid chars" } }, //validation for phoneNumber
    genderRule: { type: 'string', format: { pattern: "female|FEMALE|male|MALE|other|OTHER", flags: "gim", message: "invalid chars" } } //validation for phoneNumber
}

export const NEW_USER_KEYS_ENG = ["firstName", "lastName", "gender", "email"];
export const NEW_USER_KEYS_AR = ["اسم شخصي", "اسم عائلة", "جنس", "بريد الكتروني"];

export const USER_BLOCKED_ERROR_CODE = "USER_BLOCKED";
export const PASSWORD_ALREADY_USED_ERROR_CODE = "PASSWORD_ALREADY_USED";
export const block_time_ms_login = 600000;

export const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

