import { createI18nHooksAndProvider, TranslatedI18n } from '@hilma/i18n';
import { createI18n, Language } from './i18n'
import { createRef } from 'react';
import {
    LoginTexts,
    genericTexts,
    ErrorTexts,
    AdminTexts,
    presetMessages,
    more_options_text,
    GreetingsTexts,
    ExplainText,
    SuperadminTexts,
    GenderTexts,
    ValidationText,
    AlertsTexts,
    SendGPText,
    PopupTexts,
    AccessibilityTexts,
    StoreTexts,
    GradesTexts,
    Months,
    ExcelExplanation,
    UnsubscribeTexts
} from './texts';


const i18n = createI18n({
    login: LoginTexts,
    generic: genericTexts,
    errors: ErrorTexts,
    admin: AdminTexts,
    presetMessages: presetMessages,
    moreOptions: more_options_text,
    greetings: GreetingsTexts,
    explaintions: ExplainText,
    superadmin: SuperadminTexts,
    genders: GenderTexts,
    validation: ValidationText,
    alerts: AlertsTexts,
    send_gp: SendGPText,
    pop_up: PopupTexts,
    accessibility: AccessibilityTexts,
    store: StoreTexts,
    grades: GradesTexts,
    months: Months,
    excel_explanation: ExcelExplanation,
    unsubscribe_texts: UnsubscribeTexts,
}); // Put here all the texts you want to translate

export const {
    I18nProvider,
    contexts,
    useLanguage,
    createI18nHook,
    isLanguage,
    useChangeLanguage,
    useDirection,
    useLanguageEffect,
    useNoLanguagePathname,
    usePathLanguage,
    useSwitchedPath,
    useTransform,
    useTransformObject,
    createTranslateHook,
    createTranslatedI18nHook,
    createTranslateTextsHook
} = createI18nHooksAndProvider(Language, i18n);


export type I18n = typeof i18n;
export const useI18n = createI18nHook<I18n>();
export const useTranslate = createTranslateHook<I18n>()
export const useTranslatedI18n = createTranslatedI18nHook<I18n>();
export const useTranslateTexts = createTranslateTextsHook<I18n>();
export const TranslatedContext = contexts.TranslatedI18nContext;
export const createI18nRef = () => createRef<TranslatedI18n<I18n>>();
export const i18nRef = createI18nRef();
