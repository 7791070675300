import { createI18nText } from "../i18n";

export const SuperadminTexts = createI18nText({
    he: {
        year_over: "השנה הסתיימה? \n לחץ להעלות את כל התלמידים בכל בתי הספר כיתה אחת למעלה",
        are_you_sure_grade_up: "בטוח שברצונך להעלות כל תלמיד כיתה אחת למעלה? לא תהיה דרך חזרה",
        not_yet: "עוד לא, בטל",
        classes_update_success: "הכיתות עודכנו בהצלחה",
        clean_points: "איפוס נקודות של תלמידי בית ספר",
        make_admin: "הפוך מורה למנהל",
        make_admin_status: {
            teacher: "המורה",
            became_admin: "הפך למנהל",
            yes_make: "כן,הפוך למנהל",
            are_you_sure_that: "בטוח שברצונך להפוך את",
            to_admin: "למנהל?",
            make: "הפוך למנהל"
        },
        edit_opennings: "עריכת משפטי פתיחה",
        add_admin: "הוספת מנהל למערכת",
        clean: {
            are_u_sure_this_school: "האם אתה בטוח שברצונך לאפס את הנקודות הטובות בבית הספר",
            are_u_sure_all_schools: "האם אתה בטוח שברצונך לאפס את הנקודות הטובות בכל בתי הספר?",
            yes_clean: "כן,אפס",
            zero: "אפס",
            no_cancel: "לא, בטל",
            one_school_points: "הנקודות הטובות של בית ספר",
            all_schools_points: "הנקודות הטובות התאפסו בכל בתי הספר",
            school_to_clean: "סמל בית הספר שלו אני רוצה לאפס את הנקודות הטובות",
            all_schools: "כל בתי הספר",
            delete_open: "מחק משפט פתיחה",
            sure_to_delete: "האם אתה בטוח שברצונך למחוק את משפט הפתיחה?"
        },
        menu: {
            reset_points: "איפוס נקודות טובות",
            reset_action: "אפס נקודות",
            reset_text: "שימו לב, לחיצה על איפוס תמחק את כל הנקודות טובות בבית ספרך",
            add_teachers: "הוספת מורים למערכת",
            add_teachers_action: "הוספת מורים",
            add_teachers_text: "הוספת מורים מקובץ אקסל ויצירת סיסמאות אוטומטית.\n\n שימו לב, לכל מורה ישלח אימייל עם הסיסמה הראשונית שתינתן לו",
            add_students_classes: "הוספת תלמידים וכיתות",
            explain: "הסבר",
            add_students_classes_text: "לחץ לקבלת הסבר על דרך הוספת תלמידים וכיתות",
            add_one_teacher: "הוספת מורה למערכת",
            add_one_teacher_action: "רישום מורה",
            add_one_teacher_text: "רישום מורה יחיד למערכת"
        },
        enter_school_code: "הזן סמל בית ספר",
        school_code: "סמל בית הספר",
        schools: "בתי ספר",
        add_school: "הוספת בית ספר",
        edit_school: "עריכת בית ספר",
        existing_schools: "בתי ספר רשומים במערכת",
        succes_add_admin: "המשתמש נוסף בהצלחה כמנהל",
    },



    ar: {
        year_over: "انتهت السنة “اضغط لرفع جميع الطلاب في كل المدارس الى صف واحد للأعلى ؟",
        are_you_sure_grade_up: "هل انت متأكد من رفع كل طالب صف واحد للأعلى؟ لن تتمكن بعد ذلك التغيير ؟",
        not_yet: "ليس بعد, الغي ذلك",
        classes_update_success: "تم تحديث الصفوف بنجاح",
        clean_points: "اعادة ضبط نقاط طلاب المدرسة",
        make_admin: "اعادة تعيين معلم لمدير",
        make_admin_status: {
            teacher: "المعلم",
            became_admin: "تم اعادة تعيينه لمدير",
            yes_make: "نعم, اعادة تعيينه لمدير",
            are_you_sure_that: "هل انت متأكد من اعادة تعيين",
            to_admin: "لمدير؟",
            make: "اعادة تعيير لمدير"
        },
        edit_opennings: "تعديل الجمل الافتتاحية",
        add_admin: "اضافة مدير للبرنامج",
        clean: {
            are_u_sure_this_school: "هل انت متأكد من اعادة ضبط النقاط الجيدة في المدرسة؟",
            are_u_sure_all_schools: "هل انت متأكد من اعادة ضبط النقاط الجيدة في جميع المدارس؟",
            yes_clean: "نعم, احذف",
            zero: "صفر",
            no_cancel: "لا, الغي",
            one_school_points: "النقاط الجيدة في المدرسة",
            all_schools_points: "تم اعادة ضبط النقاط الجيدة في جميع المدارس",
            school_to_clean: "رمز مدرسته انا اريد اعادة ضبط النقاط الجيدة",
            all_schools: "جميع المدارس",
            delete_open: "حذف الجملة الافتتاحية",
            sure_to_delete: "هل انت متأكد من حذف الجملة الافتتاحية؟"
        },
        menu: {
            reset_points: "اعادة ضبط النقاط الجيدة",
            reset_action: "اعادة ضبط نقاط",
            reset_text: "الرجاء الانتباه, الضغط على اعادة الضبط تسبب في حذف جميع النقاط الجيدة في مدرستك",
            add_teachers: "اضافة معلمين الى البرنامج",
            add_teachers_action: "اضافة معلمين",
            add_teachers_text: "اضافة معلمين من ملف اكسل وانشاء كلمات مرور تلقائية. الرجاء الانتباه, لكل معلم سيتم ارسال بريد الكتروني مع كلمة المرور الأولية.",
            add_students_classes: "اضافة طلاب وصفوف",
            explain: "شرح",
            add_students_classes_text: "اضغط لتلقي الشرح على طريقة اضافة طلاب وصفوف",
            add_one_teacher: "اضافة معلم للبرنامج",
            add_one_teacher_action: "اضافة معلم",
            add_one_teacher_text: "اضافة معلم واحد للبرنامج"
        },
        succes_add_admin: "تم اضافة اسم المستخدم بنجاح كمدير",

        enter_school_code: "ادخال رمز المدرسة",
        school_code: "رمز المدرسة"
    }
})