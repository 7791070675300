import { createI18nText } from "../i18n";

export const ExcelExplanation = createI18nText({
    he: {
        "p1": "ישנן שתי דרכים להוספת",
        "p2": "הראשונה היא באמצעות מילוי טופס עם פרטי ה",
        "p3": "והשניה היא על ידי העלאת קובץ אקסל עם ה",
        "p4": "דגשים לגבי קובץ האקסל",
        "p5": "בקובץ ובו יותר מגליון אחד יש לשים לב כי הכותרות דלהלן מופיעות בראש כל גליון וגליון",
        "p6": "כל הגליונות בקובץ המועלה נקראים",
        "p7": "הכותרות בקובץ תהיינה",
        "li1": "שם התלמיד יכיל אותיות וכן ניתן להוסיף גרש, גרשיים ומקף",
        "li2": "האפשרויות בעמודה \"מגדר\" הן: זכר, נקבה או אחר",
        "li3": "במידה ותלמידים ישויכו לכיתה שטרם קיימת, הכיתה תיווצר",
        "li4": "מספר הטלפון יכיל 10 ספרות כאשר הראשונה היא 0, או 9 ספרות כאשר הראשונה היא אינה 0. ניתן להוסיף מקף לאחר 2 הספרות הראשונות (במקרה של אפס בהתחלה - לאחר 3 הספרות הראשונות), ומקף נוסף לפני הספרה הרביעית מהסוף. לידיעתכם, למספרי הטלפון תתווסף הקידומת 972",
        "p13": "דגשים בקובץ כיתות:",
        "li5": "טווח השכבות האפשרי הוא א-ח",
        "li6": "טווח הכיתות האפשרי הוא 1-15",
        "li7": "על מנת להוסיף מורה מחנך לכיתה, יש קודם לוודא כי הוא רשום במערכת, אחרת הכיתה לא תתווסף. אם טרם רשמתם מורים למערכת או אם אין ברצונכם להוסיף מורה מחנך כעת ניתן לעשות זאת במועד מאוחר יותר.",
        "to_download": "להורדת קבצי אקסל לדוגמא:",
        "child_list_example": "להורדת קובץ תלמידים לדוגמא לחץ",
        "class_list_example": "להורדת קובץ כיתות לדוגמא לחץ",
        "pm_list_example": "להורדת קובץ משפטי פתיחה לדוגמא לחץ",
        "T_first_name": "שם פרטי של המחנך",
        "T_last_name": "שם משפחה של המחנך",
        "first_name_ex": "עדי",
        "last_name_ex": "בן שמואל",
        "t_first_name_ex": "מיכל",
        "t_last_name_ex": "ישראלי",
        "preset_messages": {
            "gender": "האפשרויות בעמודה \"מגדר\" הן: זכר, נקבה או ללא",
            "category": "האפשרויות בעמודה \"קטגוריה\" הן: חברתי,רגשי,לימודי או אחר"
        }
    },
    ar: {
        "p1": "هنالد طريقتين لاضافة",
        "p2": "الأولى هي عن طريق تعبئة النموذج مع تفاصيل ال",
        "p3": "والثانية هي عن طريق رفع ملف اكسل مع ال",
        "p4": "تركيز بالنسبة لملف اكسل",
        "p5": "في الملف الذي يحتوي على اكثر من ملزمة يجب الانتباه الى عنونة كل رزمة ورزمة ",
        "p6": "كل الرزم في الملف المرفع تسمى",
        "p7": "العناوين في الملف ستكون",
        "li1": "اسم الطالب يحتوي على أحرف ويمكن اضافة فاصلة علياء, فاصلتين علويتين وواصلة",
        "li2": "الامكانيات الموجودة في العامود/”جنس”/ هم: مذكر, مؤنث, أو اخر",
        "preset_messages": {
            "gender": "האפשרויות בעמודה \"מגדר\" הן: זכר, נקבה או ללא",
            "category": "האפשרויות בעמודה \"קטגוריה\" הן: חברתי,רגשי,לימודי או אחר"
        }
    }
})