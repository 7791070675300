import { createI18nText } from "../i18n";

export const LoginTexts = createI18nText({
    he: {
        welcome_to: "-ברוכים הבאים ל",
        email: "אימייל",
        password: "סיסמה",
        enter: "כניסה",
        loading: "מתחבר...",
        forgot_p: "שכחתי סיסמה",
        reset_p: "אפס סיסמה",
        change_p: "שינוי סיסמה",
        current_p: "סיסמה נוכחית",
        new_p: "סיסמה חדשה",
        update_p: "עדכן סיסמה",
        re_login: "התחברות מחדש",
        change_succ: "הסיסמה שונתה בהצלחה, נא להמתין",
        change_psw: "אם ברצונך לשנות את הסיסמה שניתנה לך, ניתן לעשות זאת",
        identity_system: "התחברות דרך מערכת הזדהות אחידה",
        minute: "דקות",
        blocked_for: "ניסית להכנס למערכת חמש פעמים, הינך חסום/ה ל",
        set_name: "קבע שם משתמש",
        set_psw: " קבע סיסמה",
        enter_psw: " הכנס מחדש סיסמה",
        to_register: "להרשם",
        no_response: "אין תגובה, בדוק את החיבור לרשת שלך",
        welcome: "ברוכים הבאים !",
        enter_btn: "היכנס",
        reset_psw: "אפס סיסמה",
        connect: "התחבר",
        forgot_psw: "שכחת סיסמה?",
        change_psw2: "שינוי סיסמה",
        login_identity_system: "התחברות ע\"י הזדהות אחידה",
        sign_up: {
            register: "הרשמה",
            new_user: "הרשמת משתמש חדש",
            thank_u: "תודה על ההרשמה",
            pls_approve: "נא אשר את ההרשמה על ידי לחיצה : ",
            here2: " כאן ",
            end: " תודה, <br> צוות ",
            approve_mail: "אנא אשר את ההרשמה בקישור שנשלח אליך למייל ובדוק את הדואר ספאם",
            new_user2: "משתמש חדש",
            psw_verify: "אימות סיסמה",
            continue: "המשך",
            approve: "אישור",
            new_psw: "הכנס סיסמה חדשה",
            again: "הכנס שנית",
            save_psw: "שמור סיסמה",
            enter_psw: "הכנס סיסמה",
            enter_psw_again: "הכנס סיסמה שנית"
        },
    },
    ar: {
        welcome_to: "أهلاً وسهلاً بكم في ",
        email: "البريد الإلكترونيّ",
        password: "كلمة السرّ",
        enter: "دخول",
        loading: "يتم الاتصال",
        forgot_p: "نسيت كلمة المرور",
        reset_p: "اعادة تعيين كلمة المرور",
        change_p: "تغيير كلمة المرور",
        current_p: "كلمة المرور الحالية",
        new_p: "كلمة مرور جديدة",
        update_p: "تحديث كلمة المرور",
        re_login: "تسجيل الدخول مجددا",
        change_succ: "تم تغيير كلمة المرور بنجاح, الرجاء الانتظار",
        change_psw: "اذا كنت ترغب في تغيير كلمة المرور التي اعطيت لك, يمكنك عمل ذلك",
        identity_system: "تسجيل الدخول عبر نظام تحديد الهوية",
        minute: "دقائق",
        blocked_for: "حاولت الدخول للبرنامج خمسة مرات, انت محظور/ة ل",
        set_name: "اختار اسم مشترك",
        set_psw: "اختار كلمة مرور",
        enter_psw: "ادخل كلمة المرور من جديد",
        to_register: "للتسجيل ",
        no_response: "لا يوجد رد, افحص اتصال الشبكة لديك",
        welcome: "أهلا وسهلا",
        enter_btn: "أدخل",
        reset_psw: "اعادة تعيين كلمة المرور",
        connect: "تسجيل الدخول",
        forgot_psw: "نسيت كلمة المرور",
        change_psw2: "تغيير كلمة المرور",
        sign_up: {
            register: "تسجيل",
            new_user: "سجلت حساب مشترك جديد",
            thank_u: "شكرا على التسجيل",
            pls_approve: "الرجاء الموافقة على تسجيلك عن طريقة الضغط على الزر",
            here2: "هنا",
            end: "طاقم <br> شكرا",
            approve_mail: "الرجاء الموافقة على التسجيل من خلال الرابط الذي تم ارساله الى بريدك الالكتروني ",
            new_user2: "اسم مشترك جديد",
            psw_verify: "تأكيد كلمة المرور",
            continue: "أكمل",
            approve: "موافقة",
            new_psw: "أدخل كلمة مرور جديدة",
            again: "تسجيل الدخول مجددا",
            save_psw: "احفظ كلمة المرور",
            enter_psw: "ادخل كلمة المرور",
            enter_psw_again: "أدخل كلمة المرور مجددا"
        },
    }
})