import { createI18nText } from "../i18n";

export const genericTexts = createI18nText({
    he: {
        cancel: "ביטול",
        home_class: "כיתת אם",
        the_student: "התלמיד",
        delete_generic: "האם ברצונך למחוק את",
        save: "שמור",
        cancel_choice: "בטל בחירה",
        add: "הוסף",
        edit: "עריכה",
        refresh: "רענן",
        got_it: "הבנתי",
        by: "לפי",
        until: "עד",
        im_sure: "כן, בטוח",
        got_reset: "התאפסו",
        send: "שלח",
        owl: "ינשוף",
        psw: "סיסמה",
        class_plus_spc: "כיתה ",
        mail: "מייל",
        fine: "סבבה",
        got_canceled: "בוטל",
        opennings: "משפטי פתיחה",
        send_to_saved_email: "שליחה לַאימייל הרשום במערכת",
        send_email_other: "שליחה לאימייל אחר",
        email_sent_successfully: (address?: string) => address && typeof address === "string" ? `אימייל נשלח בהצלחה לכתובת ${address}!` : "אימייל נשלח בהצלחה!",
        students_plural: "תלמידים/ות",
        student_plural: "תלמיד/ה",
        the_students: "התלמידים",
        class_age: "שכבת",
        create_report: "הפקת דוח",
        gp_summary: "סיכום נקודות טובות",
        classes: "כיתות",
        class: "כיתה",
        good_point: "נקודה טובה",
        the_good_points: "הנקודות הטובות",
        students: "תלמידים",
        teacher: "מורה",
        teacher_not_in_system: "המורה אינו במערכת",
        teachers_plural: "מורים/ות",
        please_phone: "בבקשה עבור לפלאפון",
        update: "עדכן",
        no_internet_info: "אין אינטרנט, לא ניתן לטעון את המידע",
        try_again_later: "אירעה שגיאה, אנא נסו שנית מאוחר יותר",
        action_canceled: "הפעולה בוטלה",
        logout: "התנתק",
        next: "הבא",
        on_the: "על ה",
        school: "בית ספר",
        hello: "שלום",
        page_not_found: "הגעת לעמוד לא קיים",
        back_home: "חזרה לדף הבית",
        back: "חזרה",
        offline: "אין חיבור לאינטרנט",
        online: "החיבור חזר, כבר נטען",
        lines: "שורות",
        here: "כאן",
        family: "משפחה",
        private: "פרטי",
        close: "סגור",
        delete: "מחק",
        options: "אפשרויות",//not in use for now
        end: "סיום",
        everything: "הכל",
        for_teacher: "עבור המורה",
        file_too_big: "הקובץ גדול מדי",
        its_length: "אורכו",
        excel_lines: "שורות",
        upload: "העלאה",
        upload_file: "העלאת קובץ",
        registration: "רישום",
        number_class: "מספר כיתה",
        edit_general_details: "עריכת פרטי",
        chosen_student: "התלמיד/ה שנבחר/ה",
        chosen_students: "התלמידים שנבחרו",
        welcome_female: "ברוכה הבאה",
        welcome: "ברוך הבא",
        welcome_generic: "ברוך/ה הבא/ה ",
        logout_generic: "התנתק/י?",
        to_good_point: " לנקודה טובה!",
        allready_installed: 'לא ניתן להתקין, נקודה טובה כבר מותקנת אצלך במכשיר. אם היא איננה מותקנת, נא ללחוץ על הכפתור "הוספה למסך דף הבית" שבתפריט 3 הנקודות למעלה, של כרום',
        primary: "א-ת",
        number_of_points: "מספר נקודות טובות",
        want_to_share_gp: "רציתי לשתף בנקודה טובה שנשלחה",
        parents_land_pg: "דף הנחיתה להורים",
        gender: "מגדר",
        teachers: "מורים",
        teachers_list: "רשימת המורים",
        students_list: "רשימת התלמידים",
        classes_list: "רשימת הכיתות",
        opening_sent: "משפטי פתיחה",
        opening_list: "משפטי פתיחה קבועים מראש",
        points: "נקודות",
        search: "חיפוש",
        setting: "הגדרות",
        all_points: "כל הנקודות",
        notes: "תווים",
        name: "שם",
        the: "ה",
        finish: "סיים",
        rotate: "נא לסובב את הטלפון",
        sum_file_name: "סיכום-נקודות-טובות",
        user_name: "שם משתמש",
        no_internet_execute: "אין אינטרנט, לא ניתן לבצע",
        logo: "לוגו הילמ\"ה",
        no_info: "לא קיים מידע",
        share: "שיתוף",
        loading: "טוען...",
        lang: "שפה",
        back_to_about: "חזרה לעמוד אודות ושינוי שפה",
        technical_support_contact: "לתקלות ולתמיכה טכנית",
        weeklyTip: "הטיפ השבועי",
        keep_editing: "המשך לערוך",
    },
    ar: {
        cancel: "الغاء",
        home_class: "صف المنزل",
        the_student: "الطالب",
        delete_generic: "هل تريد حذف ال",
        save: "حفظ",
        cancel_choice: "الغاء الاختيار",
        add: "اضف",
        edit: "تعديل",
        refresh: "تحديث",
        got_it: "فهمت",
        by: "حسب ال",
        until: "حتى",
        im_sure: "نعم, بالطبع",
        got_reset: "تم اعادة الضبط",
        send: "ارسل",
        owl: "بومة",
        psw: "كلمة مرور",
        class_plus_spc: "صف",
        mail: "بريد الكتروني",
        fine: "صبابا ",
        got_canceled: "تم الغاء",
        opennings: "جملة افتتاحية",
        send_to_saved_email: "שליחה לַאימייל הרשום במערכת",
        send_email_other: "الإرسال إلى بريد إلكتروني آخر",
        email_sent_successfully: (address?: string) => address && typeof address === "string" ? `אימייל נשלח בהצלחה לכתובת ${address}!` : "אימייל נשלח בהצלחה!",
        students_plural: "طلاب/طالبات",
        student_plural: "طالبة",
        the_students: "الطلاب",
        class_age: "طبقة",
        create_report: "انشاء تقرير",
        gp_summary: "تلخيص نقاط جيدة",
        classes: "صفوف",
        class: "صف",
        good_point: "كلمة طيّبة",
        the_good_points: "النقاط الجيدة",
        students: "طلاب",
        teacher: "معلم",
        teacher_not_in_system: "המורה אינו במערכת",
        teachers_plural: "معلمين/ ات",
        please_phone: "الرجاء الانتقال الى الهاتف النقال",
        update: "تحديث",
        no_internet_info: "لا يوجد انترنت, لا يمكنك تحميل المعلومات",
        try_again_later: "حدث خلل, الرجاء المحاولة في وقت لاحق",
        action_canceled: "تم الغاء العملية",
        logout: "تسجيل الخروج",
        next: "القادم",
        on_the: "على ال",
        school: "مدرسة",
        hello: "مرحبا",
        page_not_found: "وصلت لصفحة غير موجودة",
        back_home: "الرجوع الى الصفحة الرئيسية",
        back: "الرجوع",

        offline: "لا يوجد اتصال في الشبكة ",
        online: "تم اعادة الاتصال وقد تم تحميله",
        lines: "اسطر",
        here: "هنا",
        family: "عائلة",
        private: "شخصي",
        close: "مغلف",
        delete: "حذف",
        options: "امكانيات",
        end: "انهاء",
        everything: "جميع",
        for_teacher: "للمعلم",
        file_too_big: "حجم الملف كبيرجدا",
        its_length: "طوله",
        excel_lines: "أسطر",
        upload: "رفع",
        upload_file: "رفع الملف",
        registration: "تسجيل",
        number_class: "رقم صف",
        edit_general_details: "تعديل تفاصيل",
        chosen_student: "الطالب/ة الذي تم/ت اختياره",
        chosen_students: "الطلاب الذي تم اختيارهم",
        welcome_female: "اهلا وسهلا بك",
        welcome: "أهلا وسهلا بك",
        welcome_generic: "اهلا وسهلا بك",
        logout_generic: "هل سجلت الخروج؟",
        to_good_point: "لكلمة طيبة!",
        allready_installed: "تم تثبيت كلمة طيبة في جهازك",
        primary: "أ-ي",
        number_of_points: "عدد نقاط جيدة",
        want_to_share_gp: "أردت المشاركة في كلمة طيبة التي تم ارسالها",
        parents_land_pg: "صفحة تعليمات للأهالي",
        gender: "جنس",
        teachers: "معلمين",
        teachers_list: "قائمة المعلمين",
        students_list: "قائمة الطلاب",
        classes_list: "قائمة الصفوف",
        opening_sent: "جمل افتتاحية",
        opening_list: "جمل افتتاحية ثابتة مسبقا",
        points: "نقاط",
        search: "بحث",
        setting: "اعدادات",
        all_points: "جميع النقاط",
        notes: "خانات",
        name: "اسم",
        the: "ال",
        finish: "انتهى",
        rotate: "اقلب الهاتف من فضلك",
        sum_file_name: "تلخيص- نقاط- جيدة",
        user_name: "اسم المستخدم",
        no_internet_execute: "لا يوجد انترنت, لا يمكن التطبيق",
        logo: "شعار هيلما",
        no_info: "لا يوجد معلومات",
        share: "مشاركة",
        loading: "يتم التحميل",
        lang: "لغة",
        back_to_about: "العودة للصفحة الرئيسية",
        technical_support_contact: "לתקלות ולתמיכה טכנית",
        weeklyTip: "الإكرامية الأسبوعية",
        keep_editing: "أكمل تعديل",
    }
});