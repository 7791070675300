import React, { useContext, useMemo } from 'react';
import { useI18n } from '../translation/create-i18n';


export const ConstsContext = React.createContext()

export const useConsts = () => useContext(ConstsContext)
export const classNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const ConstsProvider = ({ children }) => {
    const i18n = useI18n();
    const grades = useMemo(() => {
        let temp = {};
        i18n && classNumbers.forEach(item => temp[i18n.grades[item]] = item);
        return temp;

    }, [i18n])
    const consts = useMemo(() => {
        return {


            HEBREW_MONTHS: [i18n.months.january, i18n.months.february, i18n.months.march, i18n.months.april, i18n.months.may, i18n.months.june, i18n.months.july, i18n.months.august, i18n.months.september, i18n.months.october, i18n.months.november, i18n.months.december],
            HEBREW_ENGLISH_GRADES: grades,

            GET_HEBREW_GRADES: Object.keys(grades),
            GET_ENGLISH_GRADES: Object.values(grades),


            EXCEL_HEADERS: ["firstName", "lastName", "grade", "classIndex", "phoneNumber1", "phoneNumber2", "phoneNumber3", "gender"],
            HEBREW_EXCEL_HEADERS: [i18n.admin.signup.first_name, i18n.admin.signup.last_name, i18n.admin.classes.class_age, i18n.admin.classes.number, i18n.admin.phone_p_1, i18n.admin.phone_p_2, , i18n.admin.phone_p_3, i18n.generic.gender],
            EXAMPLE_STUDENTS_EXCEL_DATA: [i18n.excel_explanation.first_name_ex, i18n.excel_explanation.last_name_ex, i18n.grades[1], "1", "012-345-6789", "0123456789", i18n.genders.female],


            CLASSES_EXCEL_HEADERS: ["grade", "classIndex", "teacherFirstName", "teacherLastName"],
            HEBREW_CLASSES_EXCEL_HEADERS: [i18n.admin.classes.class_age, i18n.admin.classes.number, i18n.excel_explanation.T_first_name, i18n.excel_explanation.T_last_name],
            EXAMPLE_CLASSES_EXCEL_DATA: [i18n.grades[1], "1", i18n.excel_explanation.t_first_name_ex, i18n.excel_explanation.t_last_name_ex],
            EXAMPLE2_CLASSES_EXCEL_DATA: [i18n.grades[2], "1"],

            EXCEL_TEMPLATE_STUDENTS_NAME: "students_example_file.xlsx",
            EXCEL_TEMPLATE_CLASSES_NAME: "classes_example_file.xlsx",
            EXCEL_TEMPLATE_PRESET_NAME: "preset_messages_example.xlsx",

            STUDENTS_EXCEL_HEADERS_TRANSLATE: { [i18n.admin.signup.first_name]: "firstName", [i18n.admin.signup.last_name]: "lastName", [i18n.admin.classes.class_age]: "grade", [i18n.admin.classes.number]: "classIndex", [i18n.admin.phone_p_1]: "phoneNumber1", [i18n.admin.phone_p_2]: "phoneNumber2", [i18n.admin.phone_p_3]: "phoneNumber3", [i18n.generic.gender]: "gender" },
            PRESET_MESSAGE_EXCEL_HEADERS: [i18n.presetMessages.category, i18n.generic.gender, i18n.admin.preset_messages.text],
            PRESET_MESSAGE_EXCEL_HEADERS_TRANSLATE: { [i18n.presetMessages.category]: "category", [i18n.generic.gender]: "gender", [i18n.admin.preset_messages.text]: "text" },


            ADMIN_NO_FILTER: null,

            ADMIN_POINTS_CLASSES_SORT: "ADMIN_POINTS_CLASSES_SORT",
            ADMIN_POINTS_TEACHERS_SORT: "ADMIN_POINTS_TEACHERS_SORT",
            ADMIN_POINTS_DATE_SORT: "ADMIN_POINTS_DATE_SORT",

            originalAdminStudentsList: null,
            originalAdminClassesList: null,

            ADMIN_STUDENTS_FETCH_LIMIT: 10,// 50,
            ADMIN_CLASSES_FETCH_LIMIT: 10,// 50,
            ADMIN_POINTS_FETCH_LIMIT: 10,// 50,

            ADMIN_STUDENTS_FLOATING: "FLOATIN_GFDSA",
            // ADMIN_STUDENTS_WITH_CLASS: "WITH_CLASS_FDSAvc", // maybe later
            ADMIN_STUDENTS_ALL: "ALL_nvaTEZ",

            JUST_GO_BACK: 99, // no specific url, just history.goBack()

            SUCCESSFULLY_UPDATED_STUDENT: "SUCCESSFULLY_UPDATED_STUDENT",
        }
    }, [i18n])
    const ENG_TO_HEB_STUDENTS_EXCEL_HEADERS = (engHeader) => {
        let index = consts.EXCEL_HEADERS.findIndex(eng => engHeader === eng);
        if (!isNaN(Number(index))) return consts.HEBREW_EXCEL_HEADERS[index];
        return null
    }
    const ENG_TO_HEB_CLASSES_EXCEL_HEADERS = (engHeader) => {
        let index = consts.CLASSES_EXCEL_HEADERS.findIndex(eng => engHeader === eng);
        if (!isNaN(Number(index))) return consts.HEBREW_CLASSES_EXCEL_HEADERS[index];
        return null
    }


    const regexes = {
        FIRST_LAST_NAME: /^[a-zA-Zא-ת \u0621-\u064A '"`”’-]+$/,
        PHONE_NUMBER: /^(0[1-9][0-9]-?[0-9]{3}-?[0-9]{4})$/,
        GENDER: /female|FEMALE|male|MALE|other|OTHER/,
        PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        GOOD_POINT: "([a-zA-Z0-9א-ת\u0621-\u064A ,\-:\"'!._\r?\n])+" // must be a string!!!s
    }
    const validationRules = {
        textRule: { type: 'string', format: { pattern: regexes.FIRST_LAST_NAME, flags: "i", message: "invalid chars" } }, //validation for firstName
        gradeRule: { type: 'string', format: { pattern: '[אבגדהוזחטי]', flags: "i", message: "invalid chars" } }, //validation for grade // grades change //TODOOOOO reut shani
        classIndexRule: { type: 'number', numericality: { greaterThan: 0, lessThanOrEqualTo: 15 } }, //validation for classIndex
        phoneNumberRule: { type: 'string', format: { pattern: regexes.PHONE_NUMBER, flags: "gim", message: "invalid chars" } }, //validation for phoneNumber
        genderRule: { type: 'string', format: { pattern: "female|FEMALE|male|MALE|other|OTHER", flags: "gim", message: "invalid chars" } } //validation for phoneNumber
    }

    const validationRulesWithErrMsgs = {
        firstNameErrMSg: i18n.validation.names_err_msg, firstName: validationRules.textRule,
        lastNameErrMsg: i18n.validation.names_err_msg, lastName: validationRules.textRule,
        gradeErrMsg: i18n.validation.grade_err_msg, grade: validationRules.gradeRule,
        classIndexErrMsg: i18n.validation.class_err_msg, classIndex: validationRules.classIndexRule,
        phoneNumber1ErrMsg: i18n.validation.phone1_err_msg, phoneNumber1: validationRules.phoneNumberRule,
        phoneNumber2ErrMsg: i18n.validation.phone2_err_msg, phoneNumber2: validationRules.phoneNumberRule,
        phoneNumber3ErrMsg: i18n.validation.phone3_err_msg, phoneNumber3: validationRules.phoneNumberRule,

        genderErrMsg: i18n.generic.gender_err_msg, gender: validationRules.genderRule // not used
    }



    const NEW_USER_KEYS_ENG = ["firstName", "lastName", "gender", "email"];
    const NEW_USER_KEYS_HEB = [i18n.admin.signup.first_name, i18n.admin.signup.last_name, i18n.generic.gender, i18n.login.email];

    const NEW_USER_KEYS_TO_ENG = { [i18n.admin.signup.first_name]: "firstName", [i18n.admin.signup.last_name]: "lastName", [i18n.generic.gender]: "gender", [i18n.login.email]: "email", };
    const NEW_USER_KEYS_TO_HEB = { firstName: i18n.admin.signup.first_name, lastName: i18n.admin.signup.last_name, gender: i18n.generic.gender, email: i18n.login.email };



    const ctxValue = {
        ...consts,
        NEW_USER_KEYS_ENG,
        NEW_USER_KEYS_HEB,
        NEW_USER_KEYS_TO_ENG,
        NEW_USER_KEYS_TO_HEB,
        validationRulesWithErrMsgs,
        ENG_TO_HEB_CLASSES_EXCEL_HEADERS,
        ENG_TO_HEB_STUDENTS_EXCEL_HEADERS
    }

    return <ConstsContext.Provider value={ctxValue} >
        {children}
    </ConstsContext.Provider>
}

// * example:
// / first of all get the context: 
// const genAlertCtx = useGenAlert()
// open an alert: (nice text at the bottom left of the screen)
// genAlertCtx.openGenAlert({ text: "user info was updated successfully" });
// open a popup: (dialog with the use)
// genAlertCtx.openGenAlert({ text: "are you sure?", isPopup: { okayText: "yes", cancelText:"no, I take that back" } });
// / and to get the user's answer add:
// / 1:
// genAlertCtx.openGenAlert({ text: "are you sure?", isPopup: { okayText: "yes", cancelText:"no, I take that back" } }, (answer) => {  } );
// / or 2:
// let answer = await genAlertCtx.openGenAlertSync({ text: "are you sure?", isPopup: { okayText: "yes", cancelText:"no, I take that back" } });

// need help? -shani