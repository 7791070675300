import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import Add from "@material-ui/icons/AddToHomeScreen";
import { useI18n } from '../translation/create-i18n';

// best thing would be to convert this into a react Context, or a mobx Store.
// then, `isPWA` should be a state, and it's value should be 
// also `window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true || !!window.cordova` (cos this seems to work and know we're PWA. but maybe we don't need it, because `deferredPrompt` might be enough, as mentioned in the next line-->)
// and also `&& deferredPrompt` (which means our "beforeinstallprompt" event was triggered, meaning chrome allows our app to be installed as a PWA)
// where `deferredPrompt` is a state, default to `false`, and set to `true` by `window.addEventListener('beforeinstallprompt', ...);`
// the `.listeners` will be called by the Context/Store
// the Context/Store will also render the `AddToHomeScreen` according to a second state `open` which is controlled by other components (who can use the `isPWA` as part of the condition for the `open` prop)
// סה"כ the Context/Store will "export" 4 variables: `isOpen`:boolean, `open`:()=>void, `close`:()=>void, `isPWA`:boolean


const PWA = {
    deferredPrompt: null,

    isPWA() {
        return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true || !!window.cordova
    },

    promptInstallation(onClose, text) {
        if (!PWA.deferredPrompt)
            // chrome decided our app can't be installed as a PWA (maybe is localhost, maybe cos already installed)
            // or `.listeners` wasn't called!
            return alert(text)
        PWA.deferredPrompt.userChoice.then(({ outcome }) => {
            if (outcome === 'accepted') {
                onClose(true);
            }
        });
        PWA.deferredPrompt.prompt();
    },

    listeners() {
        // window.addEventListener('appinstalled', e => localStorage.setItem('pwagp', 'installed'));
        window.addEventListener('beforeinstallprompt', (e) => {
            // happens on mount, when chrome decides our app can be installed as a PWA.
            e.preventDefault();
            PWA.deferredPrompt = e; // save event.
        });
    }

}

const AddToHomeScreen = (props) => {
    const i18n = useI18n(i => i.moreOptions);
    const i18n_generic = useI18n(i => i.generic);
    const onClose = (e, reason) => {
        if (!reason)
            // clicked the "close" button
            localStorage.setItem('pwagp', 'deny');
        props.setOpen(false);
    }
    const IOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    const IOSFUNC = () => {
        if (isSafari) {
            return <span>
                {i18n.add_pwa.saf1}
                {" "}<img width="20" height="25" src="/images/iphone-pwa.png" />{" "},{" "}
                {i18n.add_pwa.saf2}
            </span>;
        } else if (isChrome) {
            return <span>
                {i18n.add_pwa.chrome1}
                {" "}<img width="20" height="25" src="/images/iphone-pwa.png" />{" "},
                {i18n.add_pwa.chrome2}
            </span>
        }
    }

    return (
        <Dialog style={{ zIndex: 9999 }}
            open={props.open}
            isOpen={props.open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="text-center" id="alert-dialog-title">{i18n.add_pwa.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="text-center">
                    <span>
                        {i18n.add_pwa.text}
                    </span>
                    <br /><br />
                    {IOS && IOSFUNC()}
                </DialogContentText>
                <div className="d-flex justify-content-center mt-2">
                    <img height="64" src="/images/logo.ico" />
                </div>
                <br />
            </DialogContent>
            <DialogActions className="d-flex justify-content-center">
                <Button color="primary" onClick={onClose} children={IOS ? i18n_generic.close : i18n_generic.cancel} />
                {!IOS && <Button onClick={() => PWA.promptInstallation(onClose, i18n_generic.allready_installed)} variant="contained"
                    color="primary" autoFocus style={{ color: "white", outline: "none" }}>
                    {i18n.add_pwa.add} <Add />
                </Button>}
            </DialogActions>
        </Dialog>
    );
}


export { PWA, AddToHomeScreen };
