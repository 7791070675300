import { observable, decorate, action, computed, runInAction } from 'mobx';
import Auth from '../modules/auth/Auth';
import { NEW_USER_KEYS_ENG } from '../consts/consts';
import { renameKey, validateNewUser } from '../consts/funcs';
import { convertStudentGenderToEng } from '../consts/GenderOptionsLowerCased';
import { PostHeaders } from '../consts/post-headers';
import { i18nRef } from '../index';

class UsersStore {

    name = null;
    isFirstLogin = null;
    isFirstOS = null;
    cnt = {};

    adminFetchRes = null;
    teachersList = null;
    isFirstAdminLogin = null;

    closeFirstLoginMessages = false;
    removeBubble = false
    removeBubbleTO = null
    //admin
    newUserErr = null;
    adminTeachersListFilterVal = null;

    removeSendAGPBubble = (ms = null) => {
        clearTimeout(this.removeBubbleTO)
        if (ms && Number(ms) > 0)
            this.removeBubbleTO = setTimeout(() => {
                runInAction(() => {
                    this.removeBubble = true
                })
            }, ms)
        else {
            runInAction(() => {
                this.removeBubble = true
            })
        }
    }
    removeInstrucBubbleOS = () => {
        runInAction(() => {
            this.isFirstOS = false
        })
    }
    removeInstrucPopups = () => {
        runInAction(() => {
            this.closeFirstLoginMessages = true
        })
    }

    async fetchUserFullName() {
        let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/fullName", null, true, true);
        if (err) {
            this.cnt["fetchUserFullName"] ? this.cnt["fetchUserFullName"]++ : this.cnt["fetchUserFullName"] = 1;
            if (this.cnt["fetchUserFullName"] < 3) {
                setTimeout(() => { this.fetchUserFullName() }, 1000);
                return;
            }
        }
        if (res && res.error && res.error.code === "DISABLED_SCHOOL") {
            Auth.logout(() => { }, "/login?sd=yes")
            return;
        }
        runInAction(() => {
            this.name = res;
        })
    }
    get fullName() {
        (async () => {
            if (this.name === null) {
                await this.fetchUserFullName()
            }
        })();
        return this.name;
    }

    async fetchIsFirstLogin() {
        let firstLoginLS = localStorage.getItem("firstLogin");
        if ((firstLoginLS !== null) && (firstLoginLS !== undefined) && ((firstLoginLS == "true") || (firstLoginLS == "false"))) { //there is a value in local storage for firstLogin
            runInAction(() => { this.isFirstLogin = firstLoginLS === "false" ? false : true })
            if (firstLoginLS === "true") localStorage.setItem("firstLogin", false)
        }
        else {
            let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/isFirstLogin");
            if (err) {
                this.cnt["fetchIsFirstLogin"] ? this.cnt["fetchIsFirstLogin"]++ : this.cnt["fetchIsFirstLogin"] = 1;
                if (this.cnt["fetchIsFirstLogin"] < 3) {
                    setTimeout(() => { this.fetchIsFirstLogin() }, 1000);
                    return;
                }
                return;
            }
            runInAction(() => {
                this.isFirstLogin = res.firstLogin;
            })
            localStorage.setItem("firstLogin", false)
        }
    }

    get firstLogin() {
        (async () => {
            if (this.isFirstLogin === null) {
                await this.fetchIsFirstLogin()
            }
        })();
        return this.isFirstLogin
    }

    fetchisFirstOpeningSentences() {
        (async () => {
            let firstOSLS = localStorage.getItem("firstOS");
            if ((firstOSLS !== null) && (firstOSLS !== undefined) && ((firstOSLS === "true") || (firstOSLS === "false"))) { //there is a value in local storage for firstLogin
                runInAction(() => { try { this.isFirstOS = JSON.parse(firstOSLS) } catch (err) { } })
                if (firstOSLS) localStorage.setItem("firstOS", false)
            }
            else { //no data in LS
                let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/isFirstOpeningSentences", null, true);
                if (err) {
                    this.cnt["fetchisFirstOpeningSentences"] ? this.cnt["fetchisFirstOpeningSentences"]++ : this.cnt["fetchisFirstOpeningSentences"] = 1;
                    if (this.cnt["fetchisFirstOpeningSentences"] < 3) {
                        setTimeout(() => { this.fetchisFirstOpeningSentences() }, 1000);
                        return;
                    }
                    return;
                }
                runInAction(() => {
                    this.isFirstOS = res.firstOS;
                })
                localStorage.setItem("firstOS", false)
            }
        })();
    }

    get firstOpeningSentences() {
        (async () => {
            if (this.isFirstOS === null) {
                await this.fetchisFirstOpeningSentences()
            }
        })();
        return this.isFirstOS
    }

    async signUp(password, email, firstName, lastName) {
        let [res, err] = await Auth.superAuthFetch(`/api/CustomUsers/signUp?&password=${password}&email=${email}&firstName=${firstName}&lastName=${lastName}`)
        if (err) {
            this.cnt["signUp"] ? this.cnt["signUp"]++ : this.cnt["signUp"] = 1;
            if (this.cnt["signUp"] < 3) {
                setTimeout(() => { this.signUp(password, email, firstName, lastName) }, 1000);
                return;
            }
        }
    }

    async adminFetch() {
        let [res, err] = await Auth.superAuthFetch('/api/CustomUsers/adminAccess')
        runInAction(() => {
            this.adminFetchRes = res?.success
        })
    }

    get isAdmin() {
        (async () => {
            if (this.adminFetchRes === null) {
                await this.adminFetch()
            }
        })();
        return this.adminFetchRes
    }

    async adminTeachersFetch() {
        let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/adminGetTeachers", null, true);
        if (err) {
            this.cnt["adminGetTeachers"] ? this.cnt["adminGetTeachers"]++ : this.cnt["adminGetTeachers"] = 1;
            if (this.cnt["adminGetTeachers"] < 3) {
                setTimeout(() => { this.adminTeachersFetch() }, 1000);
                return;
            }
            const error = err === "NO_INTERNET" ? i18nRef.current.generic.no_internet_info : i18nRef.current.generic.try_again_later;
            runInAction(() => {
                this.teachersList = error;
            })
            this.cnt["adminGetTeachers"] = 0;
            return false;
        }
        else runInAction(() => {
            this.teachersList = this.mergeDuplicateTeachers(res.teachersList).sort((a, b) => a.teacherFirstName > b.teacherFirstName ? 1 : a.teacherFirstName < b.teacherFirstName ? -1 : a.teacherLastName > b.teacherLastName ? 1 : a.teacherLastName < b.teacherLastName ? -1 : 0);
        })
    }
    /**
     * when teachers appear twice it's usually bcos: 1)the teacher is a home teacher in multiple classes OR 2) duplicate RoleMapping for some weird reason.
     * @returns new teachers array where a `classes` property was added. `classes` is either null (if not a home teacher) or an array of class-objects
     */
    mergeDuplicateTeachers(tArr) {
        const validClassObj = (t) => t.classIndex && t.grade ? { classIndex: t.classIndex, grade: t.grade } : null;
        const compareClasses = (classObj1, classObj2) => classObj1.classIndex && classObj2.classIndex && classObj1.grade && classObj2.grade
            && classObj1.classIndex == classObj2.classIndex && classObj1.grade == classObj2.grade;
        const uniqueTeachersById = {};
        const tNoId =[];
        tArr.forEach(t => {
            const tClass = validClassObj(t);
            if (!t || !t.id) {
                tNoId.push(t);
                return;
            }
            if (!uniqueTeachersById[t.id]) {
                // set teacher
                uniqueTeachersById[t.id] = { ...t, classes: tClass ? [tClass] : tClass };
                return;
            } else if (!tClass) { // already exists and אין חידוש
                return;
            }
            // merge classes
            if (!uniqueTeachersById[t.id].classes) {
                // set class
                uniqueTeachersById[t.id].classes = [tClass];
            }
            else if (Array.isArray(uniqueTeachersById[t.id].classes) && uniqueTeachersById[t.id].classes.findIndex(clas => compareClasses(clas, tClass)) === -1) {
                // add new class
                uniqueTeachersById[t.id].classes.push(tClass);
            }
        });
        return[...Object.values(uniqueTeachersById), ...tNoId];
    }

    get adminTeachersList() {
        (async () => {
            if (this.teachersList === null) {
                await this.adminTeachersFetch();
            }
        })();
        if (Array.isArray(this.teachersList) && typeof this.adminTeachersListFilterVal === "string" && this.adminTeachersListFilterVal.length) {
            let regexFilter = new RegExp("^" + this.adminTeachersListFilterVal, "i");
            return this.teachersList.filter(t => (regexFilter.test(t.teacherFirstName) || regexFilter.test(t.teacherLastName) || regexFilter.test(t.teacherFirstName + " " + t.teacherLastName)))
        }
        return this.teachersList
    }

    setAdminTeachersSearch(searchVal) {
        runInAction(() => {
            this.adminTeachersListFilterVal = searchVal;
        })
    }

    updateAdminTeachersList(index, firstN, lastN, gender, email) {
        if (!Array.isArray(this.adminTeachersList) || !this.adminTeachersList.length)
            return;
        runInAction(() => {
            this.adminTeachersList[index].teacherFfirstName = firstN;
            this.adminTeachersList[index].teacherLastName = lastN;
            this.adminTeachersList[index].teacherGender = gender;
            this.adminTeachersList[index].email = email;
        })
    }

    async fetchIsFirstAdminLogin() {
        let firstAdminLoginLS = localStorage.getItem("firstAdminLogin");
        if ((firstAdminLoginLS !== null) && (firstAdminLoginLS !== undefined) && ((firstAdminLoginLS === "true") || (firstAdminLoginLS === "false"))) { //there is a value in local storage for firstAdminLogin
            try { runInAction(() => { this.isFirstAdminLogin = JSON.parse(firstAdminLoginLS) }) } catch (err) { }
            if (firstAdminLoginLS === "true") localStorage.setItem("firstAdminLogin", false)
            return;
        }

        let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/isFirstAdminLogin", null, true);
        if (err) {
            this.cnt["isFirstAdminLogin"] ? this.cnt["isFirstAdminLogin"]++ : this.cnt["isFirstAdminLogin"] = 1;
            if (this.cnt["isFirstAdminLogin"] < 3) {
                setTimeout(() => { this.fetchIsFirstAdminLogin() }, 1000);
                return;
            }
            return;
        }
        runInAction(() => {
            this.isFirstAdminLogin = res.firstAdminLogin;
        })
        localStorage.setItem("firstAdminLogin", false)
    }

    get firstAdminLogin() {
        const { isAdmin } = this
        if (isAdmin === null || isAdmin === false) return isAdmin;
        (async () => {
            if (this.isFirstAdminLogin === null) {
                await this.fetchIsFirstAdminLogin()
            }
        })();
        return this.isFirstAdminLogin
    }

    async adminNewUser({ email, ...userData }, cb) { //cb will show errors/success for user
        const [res, err] = await Auth.superAuthFetch("/api/CustomUsers/createNewUser",
            {
                ...PostHeaders, body: JSON.stringify({
                    userData: {
                        ...userData, email: email.trim().toLowerCase().replace(/[\u{0080}-\u{FFFF}]/gu, "")
                    }
                })
            }, true);
        if (err) {
            const error = err === "NO_INTERNET" ? i18nRef.current.generic.no_internet_info : err;
            cb(error)
        }
        else if (res) {
            cb(false, res.newId, res.pw, res.isSendingWelcomeEmail) //cb gets an err
        }
    }

    adminAddMultTeachers = async (teachers, NEW_USER_KEYS_HEB, NEW_USER_KEYS_TO_ENG) => {

        //validating teachers obj values and keys:
        let teacher;
        for (let i = 0; i < teachers.length; i++) {
            teacher = teachers[i];
            for (let key in teacher) {
                // _check headers
                key = key.trim();
                if (!NEW_USER_KEYS_ENG.includes(key)) { // not eng key
                    if (!NEW_USER_KEYS_HEB.includes(key)) { // not heb key either!
                        return [null, { error: "INVALID_HEADER", errorMessage: `${i18nRef.current.store.found_title} ${key}, ${i18nRef.current.store.she_not_valid}\n${i18nRef.current.store.asked_titles} ${NEW_USER_KEYS_HEB.join(", ")}` }]
                    }
                    else { // is hebrew
                        // -- translate from eng to heb
                        if (typeof NEW_USER_KEYS_TO_ENG[key] !== "string") { // in case something is wrong and I can't translate the Heb headers to Eng
                            return [null, { error: "ERROR", errorMessage: `${i18nRef.current.store.err_title} ${key}.\n${i18nRef.current.store.try_again_columns} ${NEW_USER_KEYS_ENG.join(", ")}` }]
                        }
                        //_translate headers to eng
                        renameKey(teacher, { oldKey: key, newKey: NEW_USER_KEYS_TO_ENG[key] })
                        delete teacher[key];
                    }
                }
            }
            // _check teacher data
            // translate heb gender to english
            const engGender = convertStudentGenderToEng()[teacher.gender];
            if (typeof engGender !== "string") {
                return [null, { error: "INVALID_TEACHER", teacher, errorField: "gender", errorMessage: i18nRef.current.validation.gender_err_msg }];
            }
            teacher.gender = engGender.toUpperCase();
            const valid = validateNewUser(teacher);
            if (valid !== true) {
                const [errorField, errorMessage] = valid.split(';')
                return [null, { error: "INVALID_TEACHER", teacher, errorField, errorMessage }]
            }
        }
        const [res, err] = await Auth.superAuthFetch("/api/CustomUsers/a-hosafat-morim-moigfamm8fjnv", { ...PostHeaders, body: JSON.stringify({ teachers }) }, true);
        if (!err) {
            return [null, res];
        }
        console.error('adminAddMultTeachers | ERR! : ', err);
        const error = err === "NO_INTERNET" ? i18nRef.current.generic.no_internet_execute : err;
        return [error];
    }

    downloadNewTeachersFile(buffer) {
        const { schoolName, schoolCode } = this.fullName;
        const FileName = `מורים - ${schoolName} ${schoolCode}.csv`;

        // convert
        const arrayBuffer = new ArrayBuffer(buffer.data.length);
        const arr = new Uint8Array(arrayBuffer);
        for (let i = 0; i < buffer.data.length; ++i) {
            arr[i] = buffer.data[i];
        }
        const string = new TextDecoder().decode(arr);
        const blob = new Blob([string]);

        // download
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, FileName);
        } else {
            const link = document.createElement("a");
            if (!link.download) {
                // download attribute is not supported on old Chrome/... versions
                console.warn("THE DOWNLOAD FEATURE IS NOT SUPPORTED")
            }
            link.href = URL.createObjectURL(blob);
            link.download = FileName;
            link.click();
        }
    }


    addToAdminTeachersList(teachers) {// only if teachersList was fetched (this.teachersList not null - is an array) // teachers: <Array<Teacher>>
        if (!Array.isArray(this.teachersList)) return;
        runInAction(() => {
            this.teachersList = [...teachers, ...this.teachersList] // I thought it's better if the admin can see the new teachers at the top
        })
    }

    async deleteTeacher(tId) {
        return await Auth.superAuthFetch("/api/CustomUsers/delete-teacher-lm134rfdn", {
            ...PostHeaders,
            body: JSON.stringify({ tId })
        })
    }
    async removeFromAdminTeachersList(tId) {
        runInAction(() => {
            this.teachersList = this.teachersList.filter(t => t.id !== tId);
        })
    }

    adminResetTeacherPassword = async (tId) => {
        const [res, err] = await Auth.superAuthFetch("/api/CustomUsers/admin-reset-teacher-password-j1i9uihjendf", {
            ...PostHeaders,
            body: JSON.stringify({ tId })
        });
        if (res) {
            this.updateAdminPassword(tId, res.newPassword)
        }
        return [res, err];
    }
    updateAdminPassword(tId, newPassword) {
        if (!tId) return;
        runInAction(() => {
            this.teachersList = this.teachersList.map((t) => t.id === tId ? { ...t, newPassword } : t)
        });
    }

    async resetPW(oldPass, newPass, cb) {
        const resetPWfields = { OLD_PASS: 'oldPass', NEW_PASS_1: "newPass1", NEW_PASS_2: "newPass2", GENERAL: "general" }
        if (typeof oldPass !== "string") { cb({ error: { message: `${resetPWfields.OLD_PASS};` } }); return; }
        if (typeof newPass !== "string") { cb({ error: { message: `${resetPWfields.NEW_PASS_1};` } }); return; }
        let [res, err] = await Auth.superAuthFetch("/api/CustomUsers/changePassword",
            { ...PostHeaders, body: JSON.stringify({ oldPass, newPass }) });

        if (err) {
            const error = err === "NO_INTERNET" ? i18nRef.current.generic.no_internet_info : err;
            cb(error)
            return;
        }
        cb(false)
        // setTimeout(async () => { //give time for user to see the success msg (in admin_change_password_popup.jsx and in change_pw_form.js)
        let _loginRes = await Auth.login(res.email, newPass)
        // GenericTools.safe_redirect("/");
        // }, 1000);
        return;
    }

    async superAdminNewAdmin(adminData, cb) { //cb will show errors/success for user

        const [res, err] = await Auth.superAuthFetch("/api/CustomUsers/createNewAdmin",
            { ...PostHeaders, body: JSON.stringify({ adminData }) });
        if (err || !res) {
            const error = err === "NO_INTERNET" ? i18nRef.current.generic.no_internet_info : err;
            return cb(error)
        }
        return cb(false, res.pw, res.isSendingWelcomeEmail) //cb gets an err
    }


} // end of class 



decorate(UsersStore, {
    name: observable,
    fetchUserFullName: observable,
    fullName: computed,
    isFirstLogin: observable,
    firstLogin: computed,

    isFirstOS: observable,
    firstOpeningSentences: computed,

    adminFetchRes: observable,
    isAdmin: computed,

    teachersList: observable,
    adminTeachersList: computed,
    adminTeachersListFilterVal: observable,
    updateAdminTeachersList: action,
    updateAdminPassword: action,
    removeFromAdminTeachersList: action,
    addToAdminTeachersList: action,
    isFirstAdminLogin: observable,
    firstAdminLogin: computed,

    closeFirstLoginMessages: observable,
    removeInstrucPopups: action,

    removeSendAGPBubble: action,
    removeBubble: observable,
});

let usersstore = new UsersStore;//window.usersstore =
export default usersstore;

