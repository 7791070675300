import { createI18nText } from "../i18n";

export const more_options_text = createI18nText({
    he: {
        title: "אפשרויות נוספות",
        pwa: " הוספת נקודה טובה כאפליקציה",
        about: {
            title: "אודות",
            first_p1: "הילמ\"ה היא חברה לתועלת הציבור, שהוקמה ע\"י בכירים בהייטק ב- 2018 על מנת להפוך את ישראל למובילה עולמית בהיי-טק חברתי. \nלהילמ\"ה מרכז פיתוח תוסס בירושלים המאכלס 65 מפתחים צעירים 55 מתוכם הן נשים.",
            second_p1: "בשנת 2019, זכו ",
            project_clients: "נאוה בלישע ורחל אביגד",
            second_p2: " במימון פיתוח לאפליקציה במסגרת תחרות ה\"דטאתון\" של עיריית ירושלים. במסגרת התחרות, ייסדו נאוה ורחל את מיזם ",
            project_name: "נקודה טובה",
            second_p3: " שמטרתו לקדם שיח חיובי בין מורים, הורים ותלמידים.",
            developed: "מוצר זה פותח",
            at_hilma: "בהילמה - הייטק למען החברה"
        },
        add_pwa: {
            title: "הוספת האפליקציה למסך הבית",
            text: "  באפשרותך להוסיף את האפלקציה 'נקודה טובה' למסך דף הבית על מנת להיכנס בצורה נגישה וזמינה בכל עת.",
            add: "הוסף כאפליקציה",
            saf1: "על מנת להוסיף את האפליקציה למסך הבית, הכנסו לכפתור השיתוף",
            saf2: "גללו מטה והקליקו על כפתור 'הוסף למסך הבית'.",
            chrome1: "על מנת להוסיף את האפליקציה למסך הבית, היכנסו לדפדפן הSafari הכנסו לכפתור השיתוף",
            chrome2: "גללו מטה והקליקו על כפתור 'הוסף למסך הבית'."
        },
        changeLang: {
            choose: "בחר שפה:",
            title: "שינוי שפה"
        }
    },
    ar: {
        title: "امكانيات اخرى",
        pwa: "اضافة كلمة طيبة في التطبيق",
        about: {
            title: "حول",
            first_p1: "هيلما هي شركة تخدم المجتمع, أسست على يد المدراء التنفيذيين في الهايتك في سنة 2018 من أجل جعل اسرائيل رائدة على مستوى العالم في مجال التكنولوجيا الاجتماعية العالية\nلهيلما مركز تطويري حيوي في القدس\nيضم 65 مطورين شباب, 55 منهم نسا",
            second_p1: "في سنة 2019, فازوا",
            project_clients: "نافا بليشع وراحيل افيجيد",
            second_p2: "في اطار المسابقة, أسست نافا عراحيل هذه المباردة بتمويل لتطوير تطبيقات الكترونية في اطار منافسة “الداتاثون” التابع لبلدية القدس",
            project_name: "كلمة طيبة",
            second_p3: "يسعى الى بناء محادثة ايجابية بين المعلمين, أهالي وطلاب",
            developed: "تم تطوير هذا المنتج",
            at_hilma: "بهيلما- هايتك من أجل المجتمع"
        },
        add_pwa: {
            title: "اضافة التطبيق للشاشة الرئيسية,",
            text: "يمكنك اضافة التطبيق “كلمة طيبة” للشاشة الرئيسية لتسجيل الدخول بطريقة سهلة في كل وقت",
            add: "اضافة كتطبيق",
            saf1: "لاضافة التطبيق للشاشة الرئيسية , اضغطوا على زر المشاركة",
            saf2: "حركوا الفارة الى الاسفل واضغطوا على زر “اضافة للشاشة الرئيسية”",
            chrome1: "ادخلوا  على زر سفاري لاضافة التطبيق للشاشة الرئيسية, ادخلوا لمتصفح المشاركة",
            chrome2: "حركوا الفارة الى الاسفل واضغطوا على زر “اضافة للشاشة الرئيسية”"
        },
        changeLang: {
            choose: "اختر اللغة:",
            title: "تغيير اللغة"
        }
    }
})