
const PresetCategories = {
    SOCIAL: "social",
    EMOTIONAL: "emotional",
    EDUCATIONAL: "educational",
    OTHER: "other"
}


export default PresetCategories;

