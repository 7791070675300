import React from "react";

import './LinkWithIcon.scss';


interface LinkWithIconProps {
    icon: React.ComponentType;
    linkTo: string;
    onClick?: React.EventHandler<React.MouseEvent>
    linkText?: string;
    svgOnlyMarginLeft?: boolean;
    moreMargin: "10";
    aProps: JSX.IntrinsicElements['a'];
}


export function LinkWithIcon({ icon, linkTo, onClick, linkText, svgOnlyMarginLeft, moreMargin, aProps }: LinkWithIconProps) {

    const Icon = icon || null;

    return (
        <div className={"link-w-icon " + (svgOnlyMarginLeft ? "only-margin-left" : "") + (moreMargin ? `margin--${moreMargin}` : "")} >
            <a
                onClick={onClick || undefined}
                href={linkTo}
                {...aProps}
            >
                <Icon />
                <span>{linkText || linkTo}</span>
            </a>
        </div>
    );
}
