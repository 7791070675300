import { createI18nText } from "../i18n";

export const SendGPText = createI18nText({
    he: {
        who_to: "למי לשלוח נקודה טובה?",
        all_classes: "כל הכיתות",
        all_grades: "כל השכבות",
        no_teacher: "(אין מחנך)",
        male_Hteacher: "מחנך",
        female_Hteacher: "מחנכת",
        write_gp_to: "נקודה טובה ל",
        write: "כתוב נקודה טובה",
        social: "חברתי",
        emotional: "רגשי",
        edu: "לימודי",
        other: "אחר",
        sending_gp: "שליחת נקודה טובה",
        success: "נקודה טובה נשלחה בהצלחה ל",
        search_student: "חפש תלמיד",
        create_gp: {
            not_sent: "לא נשלחו נקודות טובות",
            send_gp: "שלחו נקודה טובה",
            add_gp: "הוסף נקודה טובה",
            add_gp_female: "הוסיפי נקודה טובה",
        },

        not_sent_on: "לא נשלחו נקודות טובות בחודש",
        gps_not_sent: "לא נשלחו נקודות טובות ל",
        copied: "הועתק ללוח"
    },
    ar: {
        who_to: "لمن تريد ارسال كلمة طيبة؟",
        all_classes: "جميع الصفوف",
        all_grades: "جميع الطبقات",
        no_teacher: "لا يوجد مربي",
        male_Hteacher: "مربي",
        female_Hteacher: "مربية",
        write_gp_to: "كلمة طيبة ل",
        write: "اكتب كلمة طيبة",
        social: "اجتماعي",
        emotional: "عاطفي",
        edu: "تعليمي",
        other: "اخر",
        sending_gp: "ارسال كلمة طيبة",
        success: "تم ارسال كلمة طيبة بنجاح ل",
        search_student: "البحث عن طالب",
        create_gp: {
            not_sent: "לא נשלחו נקודות טובות",
            send_gp: "שלחו נקודה טובה",
            add_gp: "הוסף נקודה טובה"
        },
        not_sent_on: "لم يتم ارسال نقاط ايجابية في الشهر",
        gps_not_sent: "لم يتم ارسال نقاط ايجابية ل",
        copied: "نسخ إلى الحافظة"
    }
})