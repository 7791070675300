import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react"
import { School } from "../common/types/school.type";
import AdminTopBar from '../components/admin/admin_top_bar';
import { SuperadminAddEditSchool } from "../components/admin/superadmin_edit_school";
import '../components/CSS/superadmin.scss';
import '../components/CSS/superadmin-schools-table.scss';

import DarkBackgroundBehindPopup from "../components/dark_background_behind_popup";
import Auth from "../modules/auth/Auth";
import { useI18n } from "../translation/create-i18n";

export const SuperAdminSchools = () => {
    const i18n = useI18n(i => ({
        existing: i.superadmin.existing_schools,
        add_school: i.superadmin.add_school
    }))
    const [schools, setSchools] = useState<School[]>([]);
    const [editSchool, setEditSchool] = useState<School | boolean>(false);
    useEffect(() => {
        getSchools()
    }, [])


    const getSchools = async () => {
        const [res] = await Auth.superAuthFetch('/api/schools/getSchools')
        if (res)
            setSchools(res.schools)
    }

    const _editSchool = (school: School) => {
        setEditSchool(school);
    }
    const closePopup = (new_school?: School, isNew?: boolean) => {
        setEditSchool(false)
        if (isNew && new_school)
            return setSchools(prev => [...prev, new_school]);
        if (!isNew && new_school) {
            const index = schools.findIndex(item => item.id == new_school.id);
            let newSchools = [...schools];
            newSchools[index].name = new_school.name;
            newSchools[index].code = new_school.code;
            setSchools(newSchools);
        }
    }

    return <div id="super-admin-container" className="superadmin-schools" >
        <AdminTopBar name="" />
        <div id="above-table">
            <h2 className="title">{i18n.existing}</h2>
            <button id="add-school" onClick={() => { setEditSchool(true) }}>{i18n.add_school}</button>
        </div>
        <table id="schools-table">
            <thead>
                <tr>
                    <th>שם</th>
                    <th>סמל מוסד</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {schools.map(school => <tr key={school.code}>
                    <td>{school.name}</td>
                    <td>{school.code}</td>
                    <td>
                        <button onClick={() => { _editSchool(school) }} id="edit-school">
                            <FontAwesomeIcon title={"edit"} icon="pencil-alt" />
                        </button>
                    </td>
                </tr>)}
            </tbody>
        </table>

        {editSchool ? <Fragment >
            <SuperadminAddEditSchool closePopups={closePopup} school={typeof editSchool != "boolean" ? editSchool : undefined} />
            <DarkBackgroundBehindPopup />
        </Fragment> : null}

    </div>
}