import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Forum } from '@material-ui/icons';
import { LinkWithIcon } from '../generic-components/LinkWithIcon';
import GenericTools from '../modules/tools/GenericTools';
import { useChangeLanguage } from '../translation/create-i18n';

import '../components/CSS/hello_page.scss';


export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


function HelloPage({ history }) {
    const changeLang = useChangeLanguage();
    useEffect(() => {
        localStorage.setItem('cancelRotate', true);
        return () => { localStorage.removeItem("cancelRotate") }
    }, [])

    function ClickLang(lang) {
        window.gtag("event", 'selected_' + lang, { event_category: "language", event_label: "language" })
        GenericTools.deleteCookieByKey('accessToken');
        GenericTools.deleteCookieByKey('klo');
        localStorage.setItem('lang', lang);
        localStorage.setItem('hello-page', false)
        changeLang(lang);
        setCookie('lang', lang, 10000);
        history.push('/login');
    }




    return <div id="hello_first_page">
        <div id="image_cont">
            <img src="/images/loginInLogo.png" alt="logo" id="welcomeLogo" />
        </div>
        <div id="video">
            <iframe width={isMobile ? '100%' : "50%"} height={isMobile ? "260" : "315"} src="https://www.youtube.com/embed/Gsqqa19Wx18?autoplay=1&listType=playlist&rel=0"
                title="נקודה טובה"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        </div>
        <div id="content">
            <h1 id="desc">
                נקודה טובה
                <br />
                לקידום שיח חיובי בבית הספר
            </h1>
            <img id="people_img" src="/images/loginImg.png" alt="welcome picture" />
            <h1 id="schoolsEntery">כניסת בתי ספר</h1>
            <span>
                <button className="lang-btn" onClick={() => ClickLang("he")}>עברית</button>
                <button className="lang-btn" onClick={() => ClickLang("ar")}>عربيه</button>
            </span>
        </div>
        <img src="images/footer.png" id="footer" alt="demo picture" />
        <div id="down_content">
            <div className='above' />
            <LinkWithIcon
                moreMargin={"10"}
                icon={Forum}
                linkText='ליצירת קשר לחצו כאן'
                linkTo='https://wkf.ms/3Pm9SCU'
                aProps={{ target: "_blank" }}
            />
            <div className='below' />
        </div>
    </div >
}
export default HelloPage;
