import { inject, observer } from 'mobx-react'

import '../CSS/admin_top_bar.scss'
import { useI18n } from '../../translation/create-i18n';
import { Link } from 'react-router-dom';


const AdminTopBar = (props) => {

    const project_name = useI18n(i => i.generic.good_point)

    const fullname = props.name || props.UsersStore.fullName

    return (
        <div id="top-bar">
            <Link id="gp-text" to={"/"}>{project_name}</Link>
            <div id="admin-name">{`${fullname ? `${fullname.firstName} ${fullname.lastName}` : ''}`}</div>
        </div>
    );
}

export default inject("UsersStore")(observer(AdminTopBar));