import { Gender } from "../enums/gender.enum";

export function validatePMText(text: string, gender: Gender, theMagicWord: string) {
    if (text.trim().length < 4) {
        return "SHORT_TEXT";
    }
    if (text && gender !== Gender.NONE) {
        let regex = new RegExp(theMagicWord, "g"); // התלמיד / התלמידה 
        let magicWordsInInput = text.match(regex) || []; //does it appear in input value?
        let wordCount = magicWordsInInput.length;
        if (wordCount === 0) {
            return "GENDER_MESSAGE"
        }
        if (wordCount > 1) {
            return "WORD_ONE_TIME";
        }

    }
    return true;
}