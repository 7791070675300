import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdminTextInput } from '../../generic-components/admin-textInput';
import { School } from '../../common/types';
import Auth from '../../modules/auth/Auth';
import { PostHeaders } from '../../consts/post-headers';
import { useI18n } from '../../translation/create-i18n';

interface SuperadminAddEditSchoolProps {
    closePopups: (school?: School, isNew?: boolean) => void,
    school?: School//this prop is used to edit a school.
}

const SuperadminAddEditSchool: React.FC<SuperadminAddEditSchoolProps> = ({ school, closePopups }) => {
    const i18n = useI18n(i => ({
        name: i.generic.name,
        cancel: i.generic.cancel,
        finish: i.generic.finish,
        school_code: i.superadmin.school_code,
        edit: i.superadmin.edit_school,
        add: i.superadmin.add_school
    }))
    const [name, setName] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        if (school) {
            setCode(school.code);
            setName(school.name)
        }
    }, [school])

    const submitting = useRef(false);



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let value = e && e.target ? e.target.value : (e && e.currentTarget ? e.currentTarget.value : '') //syntheticError ?!
        const field = e.target.id.substr(2);

        if (field === "name") {
            if (/^[א-ת 0-9 "']{0,30}$/.test(value))
                setName(value)
        }
        else if (/^[0-9]{0,6}$/.test(value))
            setCode(value);
    }


    const handleSubmit = async () => {
        if (submitting.current) return;
        submitting.current = true;
        let url = '/api/schools/addSchool', body: Partial<School> = { name, code: String(code) };

        if (school) {
            if (school.code == code && school.name.trim() == name.trim()) {//no changes
                submitting.current = false;
                closePopups();
                return;
            }
            url = '/api/schools/updateSchool';
            body.id = school.id;
        }

        const [res, err] = await Auth.superAuthFetch(url, { ...PostHeaders, body: JSON.stringify(body) });

        if (res) {
            submitting.current = false;
            if (!school) {//new instance
                if (res.school)//created
                    return closePopups(res.school, true);
                return closePopups();//exists
            }

            if (res.success)
                return closePopups({ code, name, id: school.id }, false);
            return closePopups();
        }
        if (typeof err === "string") {
            switch (err) {
                case "CODE":
                    alert("סמל המוסד שגוי")
                    break;
                case "NAME":
                    alert("יש בעיה עם שם בית הספר")
            }
        } else alert("אירעה שגיאה");
        closePopups();
    }

    const title = useMemo(() => {
        return school ? i18n.edit : i18n.add;
    }, [school])
    const disabled = code.length < 6 || name.length < 4;


    useEffect(() => {
        document.getElementById("s_name")?.focus();
    }, [])

    return (
        <div className="admin_popups" id="addEditSchool" >
            <h5 className="popup-title" >{title}</h5>
            <span className="school-info">
                <AdminTextInput inputId="s_name" label={i18n.name} value={name} onChange={handleChange} />
                <AdminTextInput inputId="s_code" label={i18n.school_code} value={code} onChange={handleChange} />
            </span>



            <div className="new-instance-buttons-container">
                <button className="cancelAdminForm" onClick={() => closePopups()}> {i18n.cancel} </button>
                <button className={`saveAdminForm ${disabled ? "btn-disabled" : ""}`} onClick={handleSubmit} disabled={disabled}> {i18n.finish} </button>
            </div>
        </div>
    );
}

export { SuperadminAddEditSchool };