import { createI18nText } from "../i18n";

export const Months = createI18nText({
    he: {
        january: "ינואר",
        february: "פברואר",
        march: "מרץ",
        april: "אפריל",
        may: "מאי",
        june: "יוני",
        july: "יולי",
        august: "אוגוסט",
        september: "ספטמבר",
        october: "אוקטובר",
        november: "נובמבר",
        december: "דצמבר"
    },
    ar: {
        january: "كانون الثاني ",
        february: "شباط",
        march: "اذار",
        april: "نيسان",
        may: "ايار",
        june: "حزيران",
        july: "تموز",
        august: "اب",
        september: "أيلول",
        october: "تشرين الأول",
        november: "تشرين الثاني",
        december: "كانون الأول"
    }
})