import { observable, decorate, action, computed, runInAction } from 'mobx';

import Auth from '../modules/auth/Auth';

import GenderOptionsLowerCased from '../consts/GenderOptionsLowerCased'

import PresetCategories from '../consts/PresetCategories';
import { getLanguage } from '../translation/GPi18n';
import { PresetCategory } from '../common/enums/preset-category.enum';
import { Gender } from '../common/enums/gender.enum';
import { PresetMessage } from '../common/types/preset-message.type';
import { validatePMText } from '../common/functions/validate-pm-text.function';
import { PostHeaders } from '../consts/post-headers';
import { i18nRef } from '../index';



class PresetMessagesStore {

    cnt: { [key in "fetchPresetMessages" | "deletePresetMessage" | "postPresetMessage" | "systemPMs"]?: number } = {};
    error = i18nRef.current?.alerts?.cant_load_info;
    presetMsgsList: Array<PresetMessage> | null = null;
    selectedCateg = 'social';
    selectedGender = GenderOptionsLowerCased.NONE;
    studentGender = '';
    studentName = '';
    osFormError = '';

    //superadmin
    superadminPMsList: Array<any> | null = null;

    pmFilter: { gender: Gender | "", school: "" | "currSchool", category: PresetCategory | "" } = { gender: "", school: "", category: "" };
    pmSearch = ""

    setOsFormError(error: string) {
        runInAction(() => {
            this.osFormError = error
        })
    }

    get getOsFormError() {
        return this.osFormError;
    }

    async fetchPresetMessages(route = "getMyPresetMessages") {
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/${route}`, undefined, true);
        if (err) {
            this.cnt["fetchPresetMessages"] ? this.cnt["fetchPresetMessages"]++ : this.cnt["fetchPresetMessages"] = 1;
            if (this.cnt["fetchPresetMessages"] < 3) {
                setTimeout(() => { this.fetchPresetMessages() }, 5000);
                return;
            }
            else {
                this.presetMsgsList = [];
                this.error = err === "NO_INTERNET" ? i18nRef.current?.generic.no_internet_info : i18nRef.current?.generic.try_again_later;
            }
        }
        else {
            runInAction(() => {
                this.presetMsgsList = res.presetMessages?.sort(sortByGenders);
            });
            setTimeout(() => { runInAction(() => { this.presetMsgsList = null }) }, 1000 * 60 * 60 * 24) //in 24hrs, from preset messages fetch.
        }
    }

    async postPresetMessage(text: string) {
        if (!text || typeof text !== "string" || !text.length) return false
        let currSelectedCateg = this.selectedCateg;
        let currGender = this.selectedGender;
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/addAPm?text=${text}&presetCategory=${currSelectedCateg}&gender=${currGender}`, null, true, true);
        if (err) {
            const errorKey = err && typeof err === "object" && err.error && typeof err.error === "object" && typeof err.error.message === "string" && err.error.message || "default";
            const ErrorMessages: Record<string, string> = { default: i18nRef.current?.generic.try_again_later || "אירעה שגיאה", INVALID: i18nRef.current?.presetMessages.invalid_opening_sentence || "המשפט אינו תקין. (התווים המיוחדים המותרים הם ,-:\"._)" }
            this.setOsFormError(ErrorMessages[errorKey] || ErrorMessages.default); //will display the error on screen
            return false;
        }
        res = res.pm;
        runInAction(() => {
            this.osFormError = '';
            this.selectedGender = GenderOptionsLowerCased.NONE
        });
        this.presetMsgsList = this.presetMsgsList!.map((pm: any) => {
            let newpm = pm;
            if (pm.new) {
                newpm.new = false;
            }
            return newpm;
        })
        this.presetMsgsList.push({ ...res, new: true, presetCategory: currSelectedCateg, gender: currGender, text: text, id: res.id });
        return true;
    }

    async deletePresetMessage(pmId: number) {
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/deletePersonalPresetMessage?pmId=${pmId}`, null, true, true)
        if (err) {
            this.cnt["deletePresetMessage"] ? this.cnt["deletePresetMessage"]++ : this.cnt["deletePresetMessage"] = 1;
            if (this.cnt["deletePresetMessage"] < 3) {
                setTimeout(() => { this.deletePresetMessage(pmId) }, 5000);
                return;
            }
            return;
        }
        setTimeout(() => {
            runInAction(() => { this.presetMsgsList = this.presetMsgsList!.filter(pm => pm.id !== pmId) }); //remove deleted pm from screen
        }, 400)
        return true;
    }

    get adminPresetMessages() {
        //returns preset messages to the opening sentences comp -> filter for relevent category
        (async () => {
            if (this.presetMsgsList === null) {
                await this.fetchPresetMessages("getSchoolPresetMessages");
            }
        })();
        if (this.presetMsgsList === null) return null;
        if (!this.pmFilter.gender && !this.pmFilter.school && !this.pmFilter.category && !this.pmSearch)
            return this.presetMsgsList;
        return this.presetMsgsList.filter(item => {
            if ((
                !this.pmFilter.gender || item.gender === this.pmFilter.gender) &&
                (!this.pmFilter.school || item.schoolId != 0) &&
                (!this.pmFilter.category || item.presetCategory == this.pmFilter.category) &&
                (!this.pmSearch || item.text.includes(this.pmSearch))
            ) return true;
            return false;
        })
    }

    setPmSearch(value: string) {
        this.pmSearch = value;
    }

    get noFilters() {
        return !this.pmSearch && Object.values(this.pmFilter).every(item => !item);
    }




    get openingSentencesByCateg() {
        //returns preset messages to the opening sentences comp -> filter for relevent category
        (async () => {
            if (this.presetMsgsList === null) {
                await this.fetchPresetMessages();
            }
        })();
        if (this.presetMsgsList === null) return null;
        const lang = getLanguage();
        return this.presetMsgsList.filter(pm => pm.presetCategory === this.selectedCateg && pm.lang === lang)
    }

    get presetMessagesByCategoryAndGender() {
        // returns preset messages to the preset keyboard -> filters for relevent gender and category
        (async () => {
            if (this.presetMsgsList === null) {
                await this.fetchPresetMessages();
            }
        })();
        if (this.presetMsgsList === null) return null
        // if (!this.presetMsgsList.length) return "empty";

        //filter the right category and gender
        let pmFilteredListToReturn = this.presetMsgsList
        const lang = getLanguage();

        pmFilteredListToReturn = pmFilteredListToReturn.filter(pm => {
            return (pm.presetCategory === this.selectedCateg) && (pm.lang === lang) && ((pm.gender.toLowerCase() === this.studentGender.toLowerCase()) || (pm.gender.toLowerCase() === GenderOptionsLowerCased.NONE))
        });

        //change to student name
        let magicWord = this.studentGender.toLowerCase() === "male" ? i18nRef.current?.presetMessages.the_student_M : i18nRef.current?.presetMessages.the_student_F;

        pmFilteredListToReturn = pmFilteredListToReturn.map(pm => {
            let newpm = { ...pm };
            newpm.text = newpm.text.split(magicWord as string).join(this.studentName);
            return newpm;
        })
        return pmFilteredListToReturn;
    }

    async systemPMs() {
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/systemPresetMessagesList`, undefined, true, true)
        if (err) {
            this.cnt["systemPMs"] ? this.cnt["systemPMs"]++ : this.cnt["systemPMs"] = 1;
            if (this.cnt["systemPMs"] < 3) {
                setTimeout(() => { this.systemPMs() }, 5000);
                return;
            }
            return;
        }
        runInAction(() => {
            this.superadminPMsList = res.presetMessages;
        });
        return true;
    }


    get superadminPMs() {
        (async () => {
            if (this.superadminPMsList === null) {
                await this.systemPMs();
            }

        })();
        return this.superadminPMsList;
    }

    async superadminDeletePM(pmId: string) {
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/deleteSystemPM`, { ...PostHeaders, body: JSON.stringify({ pmId }) });

        if (err) {
            return false;
        }
        runInAction(() => {
            this.superadminPMsList = this.superadminPMsList!.filter(pm => pm.id !== pmId)
        })
    }

    async superadminAddPM(text: string, pmCategory: PresetCategory, gender: Gender, closePopup: (arg: any) => void) {
        if (!Object.values(PresetCategories).includes(pmCategory.toLowerCase()) || !Object.values(GenderOptionsLowerCased).includes(gender.toLowerCase())) {
            return false;
        }

        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/addSystemPM`,
            { ...PostHeaders, body: JSON.stringify({ text, pmCateg: pmCategory, gender: gender }) }, true);

        if (err) {
            return false;
        }
        closePopup(null);
        runInAction(() => {
            (this.superadminPMsList && this.superadminPMsList.length) ? this.superadminPMsList.push(res.newPM) : this.superadminPMsList = [res.newPM]
        })
    }

    async adminAddPM(text: string, pmCategory: PresetCategory, gender: Gender) {
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/addSchoolPM`,
            { ...PostHeaders, body: JSON.stringify({ text, pmCateg: pmCategory, gender: gender }) }, true);

        if (err) {
            return false;
        }


        runInAction(() => {
            (this.presetMsgsList && this.presetMsgsList.length) ? this.presetMsgsList.push(res.newPM) : this.presetMsgsList = [res.newPM]
        })
        return true;
    }

    async adminDeleteSchoolPM(id: number) {
        const [res, err] = await Auth.superAuthFetch('/api/PresetMessages/deleteSchoolPM',
            { ...PostHeaders, body: JSON.stringify({ pmId: id }) }, true);
        if (res) {
            let index = this.presetMsgsList?.findIndex(item => item.id === id);
            this.presetMsgsList?.splice(index as number, 1);
        }
    }


    /**
     * This function edits existing school preset message.
     * @param message 
     * @returns success:boolean.
     */
    async adminEditSchoolPreset(message: Partial<PresetMessage>) {

        console.log('message:', message)
        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/editSchoolPM`,
            { ...PostHeaders, body: JSON.stringify(message) }, true);

        if (err) {
            return false;
        }

        runInAction(() => {
            const index = this.presetMsgsList?.findIndex(item => item.id === message.id);
            this.presetMsgsList![index as number].gender = message.gender!;
            this.presetMsgsList![index as number].presetCategory = message.presetCategory!;
            this.presetMsgsList![index as number].text = message.text!;
        })
        return true;
    }

    /**
     * 
     * @param sheets - excel sheets
     * @param callback for success/ failure with message
     * @returns 
     */
    async adminAddSchoolExcel(
        sheets: Array<{ [header: string]: number | string }>,
        callback: (suc: boolean, msg?: "SERVER_ERROR" | "HEADERS" | `CONTENT:${"text" | "gender" | "category"}`,
            value?: string | number) => void) {
        type PMRow = { presetCategory: PresetCategory, gender: Gender, text: string };

        const preset_message_excel_headers_translate: { [heb: string]: keyof PMRow } =
        {
            [i18nRef.current!.presetMessages.category]: "presetCategory",
            [i18nRef.current!.generic!.gender as string]: "gender",
            [i18nRef.current!.admin!.preset_messages!.text as string]: "text"
        };
        let categories_translate: { [he: string]: PresetCategory } = {};
        for (let pc in PresetCategory)
            categories_translate[i18nRef.current!.presetMessages!.categories![pc as PresetCategory]] = pc as PresetCategory;

        let genders_translate: { [heb: string]: Gender } = { [i18nRef.current!.genders.male]: Gender.MALE, [i18nRef.current!.genders.female]: Gender.FEMALE, [i18nRef.current!.genders.none]: Gender.NONE };
        let sheetsToSend: PMRow[] = [];

        try {//test headers
            const row = sheets[0];
            for (let header of Object.keys(row)) {
                if (!(header in preset_message_excel_headers_translate))
                    throw "ER";
            }
        } catch (err) {
            return callback(false, "HEADERS");
        }

        let trimmed_content = '', temp: PMRow = {} as PMRow, theWord = '';

        for (let row of sheets) {//test values
            temp = {} as PMRow;
            for (let header of Object.keys(preset_message_excel_headers_translate)) {
                trimmed_content = String(row[header]).trim();

                switch (preset_message_excel_headers_translate[header]) {
                    case "presetCategory":
                        if (!(trimmed_content in categories_translate))
                            return callback(false, "CONTENT:category", trimmed_content);
                        temp.presetCategory = categories_translate[trimmed_content];
                        break;
                    case "gender":
                        if (!(trimmed_content in genders_translate))
                            return callback(false, "CONTENT:gender", trimmed_content);
                        temp.gender = genders_translate[trimmed_content];

                        break;
                    case "text":
                        theWord = i18nRef.current!.presetMessages[`the_student_${temp.gender[0].toUpperCase()}` as `the_student_${"M" | "F"}`];
                        if (validatePMText(trimmed_content, temp.gender, theWord) !== true)
                            return callback(false, "CONTENT:text", trimmed_content);
                        temp.text = trimmed_content;
                        break;
                }
            }
            sheetsToSend.push(temp);
        }

        let [res, err] = await Auth.superAuthFetch(`/api/PresetMessages/uploadPMExcel`,
            {
                ...PostHeaders,
                body: JSON.stringify({ sheets: sheetsToSend })
            });
        if (res) {
            callback(true);
            this.presetMsgsList?.push(...res.newPM);
            return;
        }
        callback(false, "SERVER_ERROR")

    }
} // end of class 


decorate(PresetMessagesStore, {

    presetMsgsList: observable,
    error: observable,

    osFormError: observable,
    setOsFormError: action,
    getOsFormError: computed,

    selectedCateg: observable,
    selectedGender: observable,

    postPresetMessage: action,

    openingSentencesByCateg: computed,
    presetMessagesByCategoryAndGender: computed,

    superadminPMs: computed,
    superadminPMsList: observable,

    adminPresetMessages: computed,
    pmFilter: observable,
    pmSearch: observable,
    setPmSearch: action,
    noFilters: computed,
    adminEditSchoolPreset: action
});

let pmStore = new PresetMessagesStore;//= window.pmStore
export default pmStore;

function sortByGenders(a: any, b: any) {
    const genderA = a.gender, genderB = b.gender;
    if (genderA == genderB)
        return 0;
    if (genderA == "MALE") return -1;
    if (genderA == "NONE") return 1;
    if (genderB == "MALE") return 1;
    return -1;
}

export { PresetMessagesStore };