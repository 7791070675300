import { createI18nText } from "../i18n";

export const ExplainText = createI18nText({
    he: {
        title: "הסברים",
        opennings: "הסבר על משפטי הפתיחה",
        gp_path: "הסבר על דרך הגעת הנקודה להורים",
        manager: "הסבר למנהל",
        open: {
            os: "משפטי הפתיחה נועדו להקל עליכם המורים בתהליך הניסוח. הם משמשים כמאגר אפשרויות לניסוח וחוסכים זמן הקלדה. חשוב שהמשך הניסוח יהיה ייחודי לילד ולנקודה הטובה.",
            opening: "לפניך משפטי פתיחה מוגדרים מראש לכלל המערכת, באפשרותך למחוק אותם ו/או להוסיף משפטי פתיחה אישיים אשר יופיעו רק אצלך. לחץ על הכפתור על מנת להוסיף הודעה מוגדרת מראש",
            opening_help: "על מנת להוסיף משפט פתיחה, יש לכתוב את משפט הפתיחה הרצוי ולבחור מגדר רלוונטי למשפט. משפטי הפתיחה יופיעו בעת שליחת נקודה טובה בהתאם למגדר התלמיד ולשמו.",
            sms: {
                female: "שאת שולחת",
                male: "שאתה שולח",
                text: "מגיעות להורים בהודעה SMS. ההודעה מכילה את שם התלמיד/ה, את תוכן הנקודה הטובה וקישור לדף באינטרנט עם הנקודה הטובה. דוגמא לעמוד ניתן לראות בלחיצה"
            },
            admin_web: {
                asF: "כמנהלת",
                asM: "כמנהל",
                adminF: "מנהלת",
                adminM: "מנהל",
                should_know: "לידיעתך",
                you_can: "ביכולתך להיכנס מהמחשב לאתר נקודה טובה ועריכת",
                will_open: "תפתח בפניך "
            }
        }
    },
    ar: {
        title: "شرح",
        opennings: "شرح على الجمل الافتتاحية",
        gp_path: "شرح على طريقة وصول النقطة للأهالي",
        manager: "شرح للمدير",
        open: {
            os: "تهدف الجمل الافتتاحية للتسهيل على المعلمين في عملية الصياغة. انها بمثابة مجموعة من الامكانيات للصياعة والتي تختصر وقت الكتابة. مهم جدا ان تكملوا الصياغة بشكل خاص للطالب/ة ولكلمة طيبة.",
            opening: "أمامك جمل افتتاحية محددة مسبقا لكل البرنامج, بامكانك حذفها و/أو اضافة جمل افتتاحية شخصية التي تظهر فقط لديك. اضغط على الزر لاضافة رسائل مبنية مسبقا.",
            opening_help: "لاضافة جملة افتتاحية, يجب كتابة الجملة الافتتاحية التي ستظهر عند ارسال كلمة طيبةوفقا لجنس الطالب واسمه  ",
            sms: {
                female: "التي ترسليها",
                male: "التي ترسلها",
                text: "الرسالة تحتوي على اسم الطالب/ة, على المحتوى. رسائل نصية تصل الى الأهالي في رسالة النقطة الجيدة ورابط لصفحة انترنت. مثال لصفحة يمكنكم مشاهدته عبر الضغط على الزر"
            },
            admin_web: {
                asF: "كمديرة",
                asM: "كمدير",
                adminF: "مديرة",
                adminM: "مدير",
                should_know: "لمعلوماتك",
                you_can: "بامكانك الدخول من الحاسوب لرابط كلمة طيبة والتعديل",
                will_open: "سيفتح امامك"
            }
        }
    }
})