import React from 'react'

import './admin_general_alerts.scss'

//! made changes, for good point admin
//! לא בשימוש
export const GeneralAlert = ({ text, warning, center, noTimeout = false }) => {
    if (typeof text !== "string") { return null; }

    return (
        <div id="general-alert-container" className={`${warning ? "warning-color" : "default-color"} ${center ? "center" : ""} ${noTimeout ? "" : "timeout-animation"}`} >
            {text}
        </div>
    );
}
